<template>
<div>
  <div class="full-width category-container">
    <div class="container">
      <div class="col-3 category-filters no-mobi">
        <vue-sticky-sidebar class="sidebar" containerSelector=".container" innerWrapperSelector='.sidebar__inner' topSpacing="130">
          <div style="overflow: hidden;">
            <div v-if="fourthLevel && fourthLevel.filter(x => x.enabled).length">
              <div v-for="(item, key) in fourthLevel" v-bind:key="key">
                <div :class="'fourth-level neue ' + (item.open ? 'fl-open' : '')" v-if="item.enabled" v-bind:key="key">
                  <div class="fl-inner">
                    <span @click="toggle4th(key)">{{item.nome}}</span>
                    <div class="fa fa-plus" v-if="!item.open" @click="toggle4th(key)"></div>
                    <div class="fa fa-minus" v-if="item.open" @click="toggle4th(key)"></div>
                    <ul>
                      <li v-for="(sub, key2) in item.figli" v-bind:key="key2" @click="setFilters4th(sub)" :class="sub.enabled ? (sub.selected ? 'sl-selected' : '') : 'sl-disabled'">
                        {{sub.nome.toLowerCase().substr(0,1).toUpperCase() + sub.nome.toLowerCase().substr(1)}}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <label class="additional-filter neue" v-if="this.filtroBioEnabled">
              <input type="checkbox" :checked="this.filtriTag.includes('ORGANIC')" @change="toggleTagFilter('ORGANIC', $event)"> Bio
            </label>
            <label class="additional-filter neue" v-if="this.filtroVegEnabled">
              <input type="checkbox" :checked="this.filtriTag.includes('VEGAN')" @change="toggleTagFilter('VEGAN', $event)"> Vegan
            </label>
            <label class="additional-filter neue" v-if="this.filtroGlutEnabled">
              <input type="checkbox" :checked="this.filtriTag.includes('GLUTEN_FREE')" @change="toggleTagFilter('GLUTEN_FREE', $event)"> Gluten free
            </label>

            <br v-if="this.filtroBioEnabled || this.filtroVegEnabled || this.filtroGlutEnabled">

            <label class="additional-filter escludi-non-disponibili neue" v-if="this.filtroEsauritiEnabled">
              <input type="checkbox" :checked="this.escludiEsauriti" @change="toggleFilter('escludiEsauriti', $event)"> Mostra solo disponibili
            </label>
            <label class="additional-filter solo-promozione neue" v-if="this.filtroPromoEnabled">
              <input type="checkbox" :checked="this.soloPromozione" @change="toggleFilter('soloPromozione', $event)"> Prodotti in promozione
            </label>

            <div v-if="(this.prodottiFiltrati && this.prodottiFiltrati.length) || this.filtriTag.length || this.escludiEsauriti || this.soloPromozione">
              <br>
              <div @click="cancellaFiltri()" class="button button-delete-filtri">Cancella filtri</div>
            </div>

            <hr class="fl-divider">


            <div class="single-filter" v-for="(item, k) in secondLevel" v-bind:key="k">
              <router-link :to="'/category/' + item.categoryCode + '/' + $utils.getSlug($utils.getCorrectName(item.categoryName))" class="filter-name neue">
                <div class="filter-inner">
                  {{item.categoryName ? item.categoryName.substr(item.categoryName.indexOf(" ") + 1) : ''}}
                </div>
              </router-link>
              <div class="filter-content" v-if="thirdLevel[item.categoryCode].length">
                <router-link :to="'/category/' + item2.categoryCode + '/' + $utils.getSlug($utils.getCorrectName(item2.categoryName))" class="filter-value neue" v-for="(item2, k2) in thirdLevel[item.categoryCode]" v-bind:key="k2">
                  <div class="filter-inner">
                    {{item2.categoryName ? item2.categoryName.substr(item2.categoryName.indexOf(" ") + 1) : ''}}
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </vue-sticky-sidebar>
      </div>

      <div class="col-9 category-content">
        <div v-if="path.length">
          <Breadcrumbs :path="path" />
        </div>

        <h1 class="neue mobi-title" style="text-transform: uppercase;">{{h1}}</h1>

        <div v-if="$route.params.code == 'EC81500'" class="alcool-error">
          L'alcool è pericoloso per la salute, consumare con moderazione
        </div>

        <p class="cat-descr" v-html="catDescription"></p>

        <div class="full-width big-banner-pomodoro big-banner" v-if="catPomodoro.includes($route.params.code)">
          <div class="col-6 center-content">
            <div class="button-square m-b-5">Filiera pomodoro</div>
            <p class="big-p">Il nostro pomodoro<br/><b>non piace ai caporali.</b></p>
            <a :href="$config.contentUrl + 'filiere/pomodoro/'" class="button">scopri perch&eacute;</a>
          </div>
        </div>

        <div class="full-width big-banner-zucchero big-banner" v-if="catZucchero.includes($route.params.code)">
          <div class="col-6 center-content">
            <div class="button-square m-b-5">Filiera zucchero</div>
            <p class="big-p">Il nostro zucchero<br/><b>è amaro per chi sfrutta.</b></p>
            <a :href="$config.contentUrl + 'filiere/zucchero/'" class="button">scopri perch&eacute;</a>
          </div>
        </div>

        <div class="full-width big-banner-caffe big-banner" v-if="catCaffe.includes($route.params.code)">
          <div class="col-6 center-content">
            <div class="button-square m-b-5">Filiera caffè</div>
            <p class="big-p">Esiste un caffè<br/><b>che non è amaro per chi lavora?</b></p>
            <a :href="$config.contentUrl + 'filiere/caffe/'" class="button">scoprilo</a>
          </div>
        </div>

        <div class="full-width big-banner-riso big-banner" v-if="catRiso.includes($route.params.code)">
          <div class="col-6 center-content">
            <div class="button-square m-b-5">Filiera Riso</div>
            <p class="big-p">È&nbsp;la&nbsp;biodiversità che&nbsp;fa&nbsp;buono&nbsp;il&nbsp;riso.</p>
            <a :href="$config.contentUrl + 'filiere/riso/'" class="button">Scopri perché</a>
          </div>
        </div>

        <!--<div class="full-width little-banner" v-if="$route.params.code == 'EC72200'">
          <img src="@/assets/promo_viso.jpg" />
        </div>

        <div class="full-width little-banner" v-if="catAlimentari.includes($route.params.code)">
          <img src="@/assets/caffemanifesto.jpg" />
        </div>

        <div class="full-width little-banner" v-if="catPromoFlash.includes($route.params.code)">
          <img src="@/assets/promo_flash.jpg?v=1" />
        </div>-->

        <div class="full-width big-banner-cacao big-banner" v-if="catCacao.includes($route.params.code)">
          <div class="col-6 center-content">
            <div class="button-square m-b-5">Filiera cacao</div>
            <p class="big-p">Esiste un cioccolato<br/><b>davvero buono per tutti?</b></p>
            <a :href="$config.contentUrl + 'filiere/cacao/'" class="button">scoprilo</a>
          </div>
        </div>

        <div class="full-width big-banner-tessile big-banner" v-if="catTessile.includes($route.params.code)">
          <div class="col-6 center-content">
            <div class="button-square m-b-5">Filiera tessile</div>
            <p class="big-p">Siamo fatti<br/><b>della stessa stoffa?</b></p>
            <a :href="$config.contentUrl + 'filiere/tessile/'" class="button">scopri perch&eacute;</a>
          </div>
        </div>

        <div class="full-width little-banner" v-if="catBanner.url != '' && (typeof catBanner.url !== 'undefined')">
          <a :href="catBanner.link ? catBanner.link : '#'" target="_blank" v-if="catBanner.link">
            <img :src="catBanner.url + '?v=1'" />
          </a>
          <img :src="catBanner.url + '?v=1'" v-if="!catBanner.link" />
        </div>

        <!--<div class="full-width little-banner" v-if="catBanner.url != '' && (typeof catBanner.url !== 'undefined' && catLinkOmaggio.includes($route.params.code))">
          <router-link to="/category/EC66000/outlet">
            <img :src="catBanner.url + '?v=1'" />
          </router-link>
        </div>-->

        <CategoryView code="$route.params.code" slug="$route.params.slug" :key="childKey" :escludiEsauriti="escludiEsauriti" :soloPromozione="soloPromozione" :prodottiFiltrati="prodottiFiltrati" :filtriTag="filtriTag" />

        <!--<img src="@/assets/reso_cat.jpg" v-if="$route.params.code == 'EC65000'" style="margin-top: 40px; max-width: 100%;" />-->

        <h2 class="neue mobi-title" style="text-transform: uppercase;" v-if="h2">{{h2}}</h2>

        <p class="cat-descr" v-if="catDescription2" v-html="catDescription2"></p>

      </div>
    </div>
  </div>

  <!--<div class="full-width gray-spacer"></div>
  <div class="full-width hero lesser">
    <div class="col-7">
      <img src="@/assets/home2.png" class="cover-img" />
    </div>
    <div class="col-5 center-content">
      <div class="button-square m-b-5">I nostri valori</div>
      <p>Design made in Italy e<br/><b>valori fatti a mano</b></p>
      <div class="button">scopri perch&eacute;</div>
    </div>
  </div>
  <div class="full-width gray-spacer"></div>-->
</div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import CategoryView from '@/components/category/View'
import utils from '@/utils.js'
import axios from 'axios'
import cat_seo from '@/mockups/cat_seo'
import vueStickySidebar from "vue-sticky-sidebar"

export default {
  name: 'Category',
  data: function () {
    return {
      path: [],
      cat: {},
      parentCat: {},
      secondLevel: [],
      thirdLevel: {},
      childKey: 0,
      catBanner : {},
      catPomodoro: ['EC81402'],
      catZucchero: ['EC71100','EC81101','EC81100'],
      catTessile: ['EC65000','EC75100', 'EC75000', 'EC76400'],
      catCaffe: ['EC71000','EC81000'],
      catCacao: ['EC71200', 'EC81200', 'EC81201', 'EC81202', 'EC81203', 'EC81204'],
      catAlimentari: ['EC61000','EC71000','EC71100','EC71200','EC71300','EC71400','EC71500','EC71600'],
      catLinkOmaggio: ['EF00007','EF00027','EF00017'],
      catPromoFlash: ['EF00004'],
      catRiso: ['EC81404'],
      soloPromozione: false,
      escludiEsauriti: false,
      filtriTag: [],
      prodottiFiltrati: [],
      fourthLevel: null,
      filtroBioEnabled: false,
      filtroVegEnabled: false,
      filtroGlutEnabled: false,
      filtroEsauritiEnabled: false,
      filtroPromoEnabled: false,
      lastParentCategoryCode: null
    }
  },

  metaInfo() {
    this.$utils.adabraPageView(this, { pageType: 102, pageTitle: this.h1 });
    if(this.h1) {
      window._sbnaq.push(["trkCategoryView", this.$route.params.code]);
    }
    return {
      title: this.h1
    }
  },
  created(){
    axios.get('https://shop.altromercato.it/cms/simplestore/catBanner/getCatImage.php?category=' + this.$route.params.code)
      .then((res) => {
        this.catBanner = res.data
      })
  },
  watch: {
    $route() {
      this.initToParam()
      this.childKey++
      axios.get('https://shop.altromercato.it/cms/simplestore/catBanner/getCatImage.php?category=' + this.$route.params.code)
        .then((res) => {
          this.catBanner = res.data
        })
    }
  },

  computed: {
    h1 () {
      if(cat_seo[this.$route.params.code]) {
        return cat_seo[this.$route.params.code].h1
      }
      if(this.cat.categoryName) {
        return this.cat.categoryName.substr(this.cat.categoryName.indexOf(" ") + 1)
      }
      return ''
    },

    catDescription () {
      let cat = ''
      if(cat_seo[this.$route.params.code]) {
        return cat_seo[this.$route.params.code].desc
      }
      this.$cats.forEach((c) => {
        if (c.code == this.$route.params.code) {
          cat = c.descr
        }
      })

      return cat
    },

    h2 () {
      if(cat_seo[this.$route.params.code]) {
        return cat_seo[this.$route.params.code].h2
      }
      return false
    },

    catDescription2 () {
      if(cat_seo[this.$route.params.code]) {
        return cat_seo[this.$route.params.code].desc2
      }
      return false
    }
  },

  mounted () {
    this.initToParam()
  },

  components: {
    Breadcrumbs,
    CategoryView,
    vueStickySidebar
  },

  methods: {
    initToParam () {
      this.cat = utils.getCategory(this.$route.params.code)
      this.parentCat = utils.getSecondCategory(this.$route.params.code)
      this.secondLevel = utils.getSecondLevel(this.parentCat.categoryCode)
      this.fourthLevel = utils.getFourthLevel(utils.getCategoryTree(this.$route.params.code).map(cat => cat.categoryCode))
      if(this.lastParentCategoryCode !== this.parentCat.categoryCode) {
        this.lastParentCategoryCode = this.parentCat.categoryCode
      }
      this.cancellaFiltri();
      this.updateEnabledFilters();
      const fourthLevelPreset = window.location.hash.match(/#?f(\d+)/)
      if(fourthLevelPreset && fourthLevelPreset[1]) {
        this.fourthLevel.forEach(cat => cat.figli.forEach(filter => {
          if(filter.id == fourthLevelPreset[1]) {
            cat.open = true
            this.setFilters4th(filter)
          }
        }))
      }
      this.path = [{
        label: 'Home',
        path: '/'
      }].concat(utils.getCategoryPath(this.$route.params.code).reverse())

      this.secondLevel.forEach((item) => {
        this.thirdLevel[item.categoryCode] = utils.getSecondLevel(item.categoryCode)
      })
    },

    toggle4th (index) {
      this.fourthLevel[index].open = !this.fourthLevel[index].open
    },

    filterHasResults (filtriTag, escludiEsauriti, soloPromozione, prodottiFiltrati) {
        return utils.getAllProducts(this.$route.params.code || this.$route.params.name, false, '', filtriTag, escludiEsauriti, soloPromozione, prodottiFiltrati).length > 0
    },

    updateEnabledFilters () {
      this.fourthLevel.forEach(first => {
        let first_enabled = false
        first.figli.forEach(second => {
          second.enabled = second.prodotti.length && (second.selected || this.filterHasResults(false, false, false, second.prodotti))
          if(second.enabled) {
            first_enabled = true
          }
        })
        first.enabled = first_enabled
      })
      this.filtroBioEnabled = this.filtriTag.includes("ORGANIC") || this.filterHasResults(["ORGANIC"])
      this.filtroVegEnabled = this.filtriTag.includes("VEGAN") || this.filterHasResults(["VEGAN"])
      this.filtroGlutEnabled = this.filtriTag.includes("GLUTEN_FREE") || this.filterHasResults(["GLUTEN_FREE"])
      this.filtroEsauritiEnabled = this.escludiEsauriti || this.filterHasResults(false, true)
      this.filtroPromoEnabled = this.soloPromozione || this.filterHasResults(false, false, true)
    },

    setFilters4th (sub) {
      const products = sub.prodotti
      if(sub.selected) {
        sub.selected = false
        this.prodottiFiltrati = []
        this.updateEnabledFilters()
      } else {
        this.fourthLevel.forEach(first => first.figli.forEach(second => second.selected = false))
        sub.selected = true
        this.prodottiFiltrati = products
      }
      this.childKey++
    },

    toggleFilter (filtro, e) {
      this[filtro] = e.target.checked
      if(!e.target.checked) {
        this.updateEnabledFilters()
      }
      this.childKey++
    },

    toggleTagFilter (filtro, e) {
      this.filtriTag = this.filtriTag.filter(item => item !== filtro)
      if(e.target.checked) {
        this.filtriTag.push(filtro)
      } else {
        this.updateEnabledFilters()
      }
      this.childKey++
    },

    cancellaFiltri () {
      this.setFilters4th([])
      this.fourthLevel.forEach(item => {
        item.open = false
        item.figli.forEach(figlio => {
          figlio.open = false
          figlio.selected = false
        })
      })
      this.filtriTag = []
      this.escludiEsauriti = false
      this.soloPromozione = false
      this.updateEnabledFilters()
      this.childKey++
    }

  }
}
</script>

<style scoped>

.button-delete-filtri {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
}

.category-container {
  margin-top: 38px;
  padding-bottom: 60px;
}

.category-content h1,
.category-content h2 {
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 0.4px;
  margin: 0 0 20px 0;
}
.category-content h2 {
  margin-top: 20px;
  font-size: 24px;
}

.category-content .cat-descr {
  margin: 0 0 25px 0;
  line-height: 140%;
  font-size: 14px;
  font-weight: 500;
}

.single-filter {
  width: 100%;
  padding-right: 50px;
}

.single-filter .filter-name {
  display: block;
  font-weight: 800;
  font-size: 15px;
  margin-bottom: 20px;
  text-transform: uppercase;
  position: relative;
  height: 25px;
  line-height: 25px;
  margin-top: 0;
  cursor: pointer;
  letter-spacing: 0.5px;
}

.single-filter .filter-name:after {
  content: '';
  position: absolute;
  top: -1px;
  right: 0;
}

.single-filter .filter-content {
  padding-left: 40px;
  margin-bottom: 40px;
}

.single-filter .filter-content a {
  display: block;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 12px;
}

.filter-inner {
  display: inline-block;
}

.big-banner {
  height: 335px;
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.big-banner-pomodoro {
  background-image: url(../assets/pomodoro.jpg);
}

.big-banner-caffe {
  background-image: url(../assets/caffe.jpg);
}

.big-banner-riso {
  background-image: url(../assets/riso.jpg);
}

.big-banner-tessile {
  background-image: url(../assets/tessile.jpg);
}

.big-banner-cacao {
  background-image: url(../assets/cacao.jpg);
}

.big-banner-zucchero {
  background-image: url(../assets/zucchero.jpg);
}

body .big-p {
  font-size: 30px;
}

@media (max-width: 680px) {
  .category-content h1,
  .category-content h2 {
    text-align: center;
    font-size: 28px;
  }
  .category-content h2 {
    font-size: 22px;
  }

  .category-content .cat-descr {
    margin: 0 10px;
    max-width: none;
    margin-bottom: 30px;
  }

  body .big-p {
    font-size: 16px;
    max-width: 150px;
    line-height: 160%;
  }
}

.little-banner img {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

.fl-divider {
  width: 80%;
  color: #eaeaea;
  box-shadow: none;
  margin: 15px 0;
  border: 0;
  border-top: 1px solid #aaa;
}

.fourth-level {
  width: 80%;
  min-height: 30px;
  line-height: 30px;
  overflow: hidden;
  max-height: 30px;
  position: relative;
}

.fourth-level.fl-open {
  max-height: 1000px;
}

.fourth-level .sl-selected {
  position: relative;
}
.fourth-level .sl-selected::before {
  content: '✔︎';
  display: block;
  position: absolute;
  right: 100%;
  margin-right: 5px;
}

.fourth-level .sl-disabled {
  display: none;
}

.fl-inner {
  width: 100%;
  height: auto;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.fl-inner span {
  display: block;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 20px;
  text-transform: uppercase;
  height: 25px;
  line-height: 25px;
  margin-top: 0;
  cursor: pointer;
  letter-spacing: 0.5px;
  font-style: italic;
  color: #555;
}

.fl-inner .fa {
  position: absolute;
  top: 6px;
  right: 5px;
  cursor: pointer;
  color: #555;
  font-size: 14px;
}

.fl-inner li, .fl-inner ul {
  list-style: none;
}

.fl-inner li {
  cursor: pointer;
}

.fl-inner li.fl-open {
  color: #881e2e;
}

.additional-filter {
  margin: 10px 0 0 0;
  font-size: 15px;
  display: block;
}
</style>
