export default {
	"EC83101": {
		"h1": "Piatti e ceramiche",
		"desc": "Una tavola originale richiede decorazioni semplici ma d'effetto. Per rendere speciali i tuoi momenti di convivialità, noi di Altromercato abbiamo selezionato i migliori piatti e le più particolari ceramiche artigianali provenienti da tutto il mondo. Dal Vietnam al Nepal, senza dimenticare la Thailandia: piatti e ceramiche sono interamente realizzati a mano, con amore e grande cura, nel totale rispetto dei diritti dei lavoratori che da sempre rimane una delle nostre priorità. Anche a tavola, fai una scelta consapevole prediligendo l'artigianato sostenibile.",
		"h2": "Piatti e ceramiche artigianali Altromercato",
		"desc2": "Dal blu profondo del mare, con i suoi pesciolini stilizzati, ai colori caldi della terra, e i suoi cerchi concentrici che ricordano gli strati che formano l'anima più profonda del nostro pianeta. I piatti e le ceramiche artigianali della linea On Earth - ethical home si ispirano alla meraviglia che ci circonda, e sono adatti a ogni stile di arredamento: dal più classico, al più moderno. Tutti i prodotti Altromercato sono realizzati con un occhio di riguardo alla catena di produzione e al rispetto degli artigiani produttori. E non solo: le materie prime sono tutte sostenibili e a basso impatto ambientale."
	},
	"EC74100": {
		"h1": "Nascita e Battesimo",
		"desc": "Per raccontare la bellezza di una nuova vita e celebrarne i primi momenti importanti, noi di Altromercato abbiamo selezionato per te tante proposte per regali nascita e bomboniere equosolidali per il battesimo provenienti da tutto il mondo. Dal Perù al Vietnam, passando per India e Bangladesh: i nostri prodotti sono tutti realizzati a mano con cura e racchiudono in sé le tradizioni di popoli lontani. In Altromercato crediamo fortemente che ognuno di noi possa fare la differenza con le sue scelte e i suoi acquisti. Ecco perché ti proponiamo manufatti realizzati in materiali naturali e amici dell'ambiente - come il legno ricavato da foreste sostenibili - e creati all'interno di una filiera che rispetta il lavoro degli artigiani produttori. ",
		"h2": "Tutti i regali nascita e le bomboniere per il battesimo Altromercato",
		"desc2": "Le persone sono le vere protagoniste del mondo Altromercato, ed è grazie alla collaborazione con loro - con gli artigiani, in questo caso - che nascono gli oggetti che abbiamo attentamente selezionato come bomboniere per il battesimo o regali per la nascita. Una cicogna di terracotta smaltata a mano, che richiama l'arrivo di una nuova vita; una tartaruga portafortuna fatta all'uncinetto; un angelo in legno sostenibile, simbolo di protezione. Con i nostri prodotti equosolidali scegli di celebrare la vita, con un occhio di riguardo per l'ambiente e per chi produce ciò che regalerai."
	},
	"EC85001": {
		"h1": "Giacche, capispalla",
		"desc": "Un'altra idea di moda è possibile. Le giacche etniche e i capispalla della linea On Earth - ethical fashion di Altromercato nascono da un processo di economia circolare che mira a un'attenta azione di recupero di materiali inutilizzati. Un progetto che nasce per ridurre gli sprechi del settore tessile e allungare la vita dei prodotti. Tutti i capi di abbigliamento artigianali sono realizzati a partire dalle giacenze raccolte nei magazzini tessili di tutta Italia. I capi invenduti e i tessuti inutilizzati vengono poi lavorati in nuove creazioni dalle cooperative che collaborano con noi di Altromercato per realizzare i capi delle collezioni On Earth - ethical fashion per creare un impatto sociale sul territorio nazionale e favorire l'occupazione.",
		"h2": "Le giacche etniche con lo stile Altromercato",
		"desc2": "I materiali utilizzati per realizzare una giacca da donna ecosostenibile spaziano dal cotone felpato o elasticizzato alla morbida e pregiata lana di alpaca. l'operazione di recupero va oltre alla classica idea di riciclo e si pone l'obiettivo di dare nuovo valore ai tessuti recuperati grazie a lavorazioni artigianali e rifiniture di pregio. Il design dei capispalla etnici unisce così linee essenziali e classiche con scritte e decorazioni che impreziosiscono e trasformano una semplice giacca etnica fino a renderla un capo carico di stile personale all'insegna della sostenibilità."
	},
	"EC82000": {
		"h1": "Saponi",
		"desc": "Il cambiamento verso un consumo più consapevole dei prodotti per l'igiene personale non è mai stato tanto rinfrescante e profumato. Vedrai: in questi saponi naturali Natyr, rispettosi dell'ambiente, lo stile Altromercato è più intenso che mai, merito anche delle profumazioni esotiche ed ecocompatibili a cui è impossibile resistere. Ci impegniamo a offrirti prodotti certificati e naturali, realizzati e distribuiti in una filiera equa e solidale. Il commercio sostenibile come lo intendiamo noi ti dona bellezza e benessere, e si batte per difendere il pianeta in cui viviamo. Le confezioni riutilizzabili in cui impacchettiamo i nostri prodotti artigianali per l'igiene ne sono un chiarissimo esempio. Pochette e sacchetti in cotone si trasformano in magnifici accessori da riciclare per ridurre gli sprechi con concretezza e creatività.",
		"h2": "I saponi naturali ecosostenibili Natyr Altromercato",
		"desc2": "Utilizzare i nostri prodotti naturali per l'igiene vuol dire scoprire i profumi del mondo. Palestina, Thailandia ed Ecuador: le loro meravigliose ricchezze si prenderanno cura della tua pelle, con i valori Altromercato di sempre. Con Natyr abbiamo ideato una linea di prodotti per l'igiene personale innovativa, concentrando esperienze tradizionali locali che garantiscono una produzione consapevole e sostenibile. Ogni sapone naturale Altromercato è unico per le sue capacità, gli aromi e una storia equa e solidale da provare sulla pelle. Dalle coltivazioni biologiche fin dentro casa tua, ti proponiamo di scoprire insieme a noi tutto il bello che esiste al mondo."
	},
	"EC81202": {
		"h1": "Cacao e preparati per dolci",
		"desc": "Ami il cioccolato in tutte le sue forme? Allora troverai irresistibili il cacao in polvere e i preparati per dolci equosolidali di Altromercato. Frutti diretti della nostra filiera etica del cacao, questi prodotti soddisfano il palato e il cuore. I più piccoli potranno gustare una merenda sana e golosa a base di latte e cacao solubile, mentre gli adulti si godranno una tazza di cioccolata dall'intenso gusto fondente. Con i nostri preparati, inoltre, potrai realizzare dolci ottimi con ingredienti genuini. Il tutto sapendo che il nostro cacao in polvere non mina la biodiversità dei Paesi produttori e non lede i diritti dei coltivatori e delle loro famiglie. È un cacao buono, per tutti.",
		"h2": "I preparati per dolci e il cacao in polvere Altromercato",
		"desc2": "La nostra filiera del cacao si pone come obiettivo quello di avere un elevato impatto sociale. Per questo garantiamo migliori condizioni di lavoro ai piccoli coltivatori con cui collaboriamo e li stimoliamo a migliorare continuamente. Rispettiamo e tuteliamo i loro diritti, nutrendo la relazione che ci lega a loro in modo che non sia solo commerciale ma anche di supporto. Coltiviamo il nostro cacao solubile e gli ingredienti per i preparati per dolci in maniera sostenibile e non aggressiva, per rispettare l'ambiente. Nei prodotti che noi di Altromercato ti offriamo, cacao in polvere incluso, oltre al gusto inconfondibile trovi tutto questo."
	},
	"EC83104": {
		"h1": "Tazzine caffè e zuccheriere",
		"desc": "Il primo caffè del mattino, o quello del pomeriggio con gli amici, è un rito irrinunciabile delle nostre giornate. Perché non versarlo in tazzine artigianali, fatte con amore e nel pieno rispetto dell'ambiente? Le tazzine e le zuccheriere artigianali della collezione di Altromercato sono la tua scelta consapevole per sostenere il commercio equosolidale. Decorazioni geometriche, dove mare e terra si fondono con le antiche tradizioni manifatturiere di Paesi lontani come Thailandia, Vietnam e molti altri. Tutte le pitture, realizzate a mano direttamente sulla ceramica, rallegreranno la tua colazione o i piccoli momenti di convivialità pomeridiani. Equità, rispetto per il lavoro e per il pianeta: una missione, la nostra, che coinvolge l'intera filiera produttiva.",
		"h2": "Le zuccheriere e le tazzine artigianali di Altromercato",
		"desc2": "L'attenzione ai lavoratori e al bene del pianeta è alla base di tutti i nostri prodotti, incluse tazzine e zuccheriere artigianali Altromercato. Le nostre collezioni riportano decori adatti ai gusti di tutti, e ogni manufatto è unico e realizzato nel rispetto delle antiche tradizioni d'artigianato del Paese di provenienza. Scegli la tazzina e la zuccheriera che più rispecchiano il tuo stile e goditi il tuo caffè, consapevole di aver fatto la scelta giusta."
	},
	"EC81501": {
		"h1": "Succhi e tè freddo",
		"desc": "Noi di Altromercato abbiamo infatti selezionato i migliori ingredienti in Europa e nel mondo, per assicurarti succhi e tè freddi che siano tanto gustosi quanto naturali. I nostri succhi equosolidali, infatti, contengono frutta di ottima qualità e solo un pizzico di zucchero di canna proveniente dalla nostra filiera etica. I tè freddi sono realizzati per infusione, con foglie pregiate cresciute ad alta quota. E tutte queste bevande sono biologiche, vegane e senza glutine: buone e dissetanti per tutti.",
		"h2": "I tè freddi e i succhi equosolidali di Altromercato",
		"desc2": "Le scelte che compiamo ogni giorno raccontano chi siamo e rispecchiano i nostri valori. Se per te è importante che nessuno venga sfruttato per produrre ciò che bevi e mangi, e che il lavoro dei coltivatori e degli artigiani del mondo venga valorizzato come deve, allora i prodotti equosolidali sono l'opzione giusta. I nostri succhi di frutta e tè freddi sono l'ideale per portare sulla tua tavola sapori che parlano di luoghi lontani. Sorseggiarli significa sostenere piccoli coltivatori che lavorano all'altro capo del mondo, e favorire piantagioni sostenibili che rispettano la biodiversità. Con i succhi e tè freddi Altromercato la tua pausa sarà buona due volte."
	},
	"EC85200": {
		"h1": "Anelli",
		"desc": "Una selezione di anelli etnici di alta qualità realizzati con pregiati materiali di origine naturale. Una proposta di ornamenti di grande eleganza per impreziosire le tue mani e accompagnarti ogni giorno con stile e sostenibilità. A dare prestigio ai nostri anelli etnici - e a tutti i gioielli e bijoux del marchio On Earth - ethical fashion - non è solo la qualità delle materie prime utilizzate, ma soprattutto la ricerca e la cura di lavorazioni artigianali uniche nel loro genere. Ogni anello racconta una storia diversa di popoli e tradizioni, per uno stile esotico dall'elevato impatto etico e sociale.",
		"h2": "Il fascino degli anelli etnici Altromercato",
		"desc2": "Ogni anello etnico selezionato per te da noi di Altromercato ha un valore aggiunto: la lavorazione a mano eseguita dai nostri partner. I gioielli di On Earth - ethical fashion nascono da tradizioni provenienti da tutto il mondo. Un viaggio nella bigiotteria etnica attraverso materie prime come pietre semipreziose e minerali, ma anche materiali naturali e di recupero. In questo percorso dall'India all'America Latina, passando per Africa ed Europa, la cura dei dettagli non è solo un'ulteriore prova del valore dei nostri anelli etnici, ma è una garanzia del rispetto del lavoro e dell'impegno delle artigiane e artigiani che li realizzano per te."
	},
	"EC81701": {
		"h1": "Torroni e croccanti",
		"desc": "La tradizione dei dolci natalizi italiani si trasforma grazie all'incontro con ingredienti esotici coltivati con passione in filiere etiche e controllate. I croccanti e torroni artigianali che abbiamo selezionato per il negozio online di Altromercato portano a tavola nuovi sapori per i momenti speciali in famiglia. Le materie prime scelte e lavorate con cura assieme ai produttori con cui collaboriamo incontrano le ricette della pasticceria delle feste per offrirti momenti unici di dolcezza. Il Natale con Altromercato si arricchisce di una nuova consapevolezza, per un mondo più giusto e solidale.",
		"h2": "Un viaggio nel gusto attraverso torroni e croccanti",
		"desc2": "Per preparare i nostri croccanti e torroni artigianali, noi di Altromercato abbiamo individuato gli ingredienti migliori da ogni angolo del mondo. Anacardi dall'India, noci dall'Amazzonia, zucchero di canna, cioccolato, miele e altre materie prime provenienti da coltivazioni equosolidali, rispettose dell'ambiente e del territorio di origine. Dal lavoro fianco a fianco con i nostri produttori partner è nato un nuovo modo di gustare la dolcezza delle feste natalizie. Un viaggio nei sapori di esperienze lontane, per portare in tavola un nuovo modo di vivere un classico momento di gioia e amore. Nel pieno rispetto delle tradizioni, del lavoro delle persone e del pianeta che ci ospita."
	},
	"EC85002": {
		"h1": "Maglioni e cardigan",
		"desc": "I maglioni e i cardigan etnici della collezione On Earth - ethical fashion di Altromercato sono l'abbinamento ideale tra stile e risposta sostenibile alla fast fashion. Immagina la morbida sensazione di un capospalla etnico, accarezza tessuti di qualità e condividi con noi il profondo rispetto per i doni che ci offre il nostro pianeta. Il commercio equo e solidale che promuoviamo è fantastico sia da indossare che da sostenere. Alpaca, lana merino e i migliori tessuti ecocompatibili terranno al caldo fianchi, spalle e cuore. Perché scegliere i capi d'abbigliamento Altromercato vuol dire fare affidamento su un concetto di produzione differente, trasparente e responsabile. ",
		"h2": "Maglioni e cardigan ecosostenibili e alla moda",
		"desc2": "Indossare un maglione etnico o un cardigan equosolidale è un'esperienza di bellezza, accompagnata dallo spirito di condivisione Altromercato. Le ultime tendenze della moda si intrecciano con le storie di Paesi lontani che producono tessuti filati a regola d'arte. Sbizzarrisciti negli abbinamenti che preferisci e scopri i colori e i tagli che più stuzzicano il tuo gusto: sarà in ogni caso una scelta consapevole. Dal lavoro sul posto alla logistica, la nostra filiera di produzione vuole rivoluzionare il mercato: per te maglioni e cardigan etici, per i produttori del mondo finalmente una possibilità."
	},
	"EC84201": {
		"h1": "Oggetto bomboniera comunione e cresima",
		"desc": "Scegliere l'oggetto giusto per ricordare un momento speciale della vita è importante tanto quanto fare un acquisto consapevole, optando per prodotti realizzati nel pieno rispetto dei diritti dei lavoratori e dell'ambiente. Le bomboniere equosolidali per comunione e cresima di Altromercato sono perfette per unire equità, solidarietà e buon gusto. Stoviglie, decorazioni per la casa o piccoli gadget da portare con sé ovunque: sul nostro shop online trovi regali sostenibili totalmente realizzati a mano e provenienti da ogni parte del mondo, perfetti per diventare delle memorabili bomboniere equosolidali.",
		"h2": "Le bomboniere equosolidali per comunione e cresima di Altromercato",
		"desc2": "Per noi di Altromercato, la sostenibilità e il rispetto dei lavoratori sono le motivazioni dietro ogni scelta che compiamo. È per questo motivo che ti proponiamo solo prodotti equosolidali, anche quando si tratta di bomboniere per la prima comunione o la cresima. Gli oggetti che abbiamo selezionato per te vengono da alcuni dei Paesi più creativi del mondo, dall'Oriente al Sud America, e vogliono premiare un artigianato che fa bene al cuore e al pianeta. Prestiamo particolare attenzione alla filiera di produzione di ogni singola proposta del nostro shop perché, se è vero che ciò che acquistiamo ci definisce, è bene compiere una scelta di consumo etico."
	},
	"EC81201": {
		"h1": "Cioccolatini e praline",
		"desc": "I cioccolatini e le praline equosolidali di Altromercato sono perfetti per concedersi un piccolo, dolce piacere. Non c'è niente di meglio dell'intenso sapore del cacao che si sprigiona fin dal primo assaggio, e che viene esaltato dagli altri deliziosi ingredienti. Anzi, qualcosa di meglio c'è: è la consapevolezza del fatto che i nostri cioccolatini fanno del bene sia a chi li assapora sia a chi li produce. Il cacao che usiamo per le nostre praline proviene infatti da una filiera etica che lo rende buono davvero, per tutti. Una filiera che dà vita a un prodotto di qualità, senza dimenticare quanto sia importante rispettare l'equilibrio dell'ecosistema e i diritti dei lavoratori.",
		"h2": "Il gusto intenso di praline al cacao e cioccolatini",
		"desc2": "Secondo noi il cioccolato, per essere davvero irresistibile, deve essere buono per tutti. Non basta il sapore paradisiaco: serve anche un'etica di produzione che si prenda cura della biodiversità dell'ambiente dove il cacao viene coltivato, e dei piccoli produttori che lo fanno crescere con dedizione. È grazie a loro se noi di Altromercato possiamo offrirti cioccolatini e praline così invitanti e gustosi. Piccole pepite di dolcezza, in cui il cacao da filiera equosolidale si combina con zucchero di canna, nocciole, anacardi e granelle croccanti. Perfetti per goderseli a fine pasto, o per regalarli ad altri: ogni occasione è quella giusta."
	},
	"EC81101": {
		"h1": "Zucchero grezzo",
		"desc": "Scegli una nuova idea di dolcezza con lo zucchero grezzo di canna di Altromercato: una selezione di prodotti di altissima qualità realizzati con tecniche di produzione etiche e sostenibili. Con i nostri zuccheri non raffinati contribuisci a sostenere piccole realtà agricole che hanno deciso di cambiare le logiche della produzione industriale. La filiera dello zucchero si basa sullo sfruttamento dei braccianti, e su un uso sconsiderato delle risorse naturali. I produttori che noi di Altromercato abbiamo deciso di supportare si impegnano per offrire migliori condizioni di lavoro a tutte le persone coinvolte nella produzione e a ridurre al minimo l'impatto ambientale delle coltivazioni.",
		"h2": "Zucchero grezzo di canna: un'alternativa due volte dolce",
		"desc2": "Portare nella tua cucina lo zucchero grezzo di canna significa scegliere un'alternativa due volte dolce: per te e per il pianeta. Perché in ogni confezione ritrovi il sapore autentico dello zucchero da agricoltura biologica, libero da processi di raffinazione industriale. Un ritorno a un gusto naturale insuperabile, in cui riesci a percepire il legame autentico con la terra. Le canne da zucchero vengono infatti coltivate e lavorate in una filiera corta e controllata. Un processo di produzione senza sfruttamenti intensivi del terreno e delle sue risorse, pensato per preservare i delicati equilibri degli ecosistemi di origine. E per restituirti la vera esperienza dello zucchero grezzo di canna."
	},
	"EC81405": {
		"h1": "Legumi",
		"desc": "Fare commercio equo e solidale significa anche prendersi cura del pianeta in cui viviamo. Per portare questi legumi nella tua cucina, per esempio, noi di Altromercato lavoriamo a stretto contatto con una filiera di produttori responsabili che coltivano baccelli buoni in tutti i sensi. Le coltivazioni biologiche proteggono la biodiversità ambientale e salvaguardano la qualità del terreno per continuare a regalarci legumi fondamentali per la nostra dieta. Così il prodotto che finisce nel tuo piatto è gustoso al palato e ricco di proteine e fibre per garantirti sempre una sana alimentazione.",
		"h2": "Legumi Altromercato sostenibili",
		"desc2": "Dai coltivatori, passando per chi si occupa del confezionamento e poi del trasporto sul territorio, noi di Altromercato mettiamo a tua disposizione tutte le informazioni sui nostri legumi. La nostra è una filiera trasparente: ci impegniamo insieme a creare valore, a distribuirlo tra tutti gli attori della produzione e dare vita a un mercato più giusto. Dove contano le persone, le comunità, l'ambiente - oltre a te e al tuo benessere. Noi di Altromercato mettiamo in ogni confezione di legumi biologici tutta la bontà della natura e una bella porzione di sostenibilità."
	},
	"EC82001": {
		"h1": "Bagno e doccia",
		"desc": "Scegliere prodotti per l'igiene personale sostenibili è uno dei primi passi da compiere per un consumo più consapevole. I bagnodoccia naturali di Natyr Altromercato contengono un mix di oli essenziali, piante e fiori selezionati e raccolti con attenzione in diversi Paesi del mondo, e vengono realizzati nel totale rispetto dell'ambiente e dei lavoratori coinvolti nel processo produttivo. I nostri prodotti artigianali per l'igiene sono adatti a ogni routine di bellezza e a tutti i tipi di pelle, anche le più delicate. Essenze uniche, in grado di trasportarti in luoghi esotici e lontani con pochi gesti quotidiani.",
		"h2": "I benefici della natura nei prodotti ecosostenibili Altromercato",
		"desc2": "La linea di prodotti per bagno e doccia di Altromercato profuma d'Oriente e di Sud America. Terre lontane che donano gli ingredienti migliori per i prodotti naturali per l'igiene che trovi nel nostro negozio online. l'aroma di fiori e piante si mescola a consistenze morbide e setose, per idratare e igienizzare la tua pelle, giorno dopo giorno. Tutti i detergenti della linea Natyr sono pensati per rispettare l'equilibrio della pelle, le risorse del pianeta e il lavoro di chi li produce."
	},
	"EC81200": {
		"h1": "Tavolette e snack cioccolato",
		"desc": "Noi di Altromercato abbiamo selezionato per te snack e tavolette di cioccolata di tanti gusti possibili. Siamo partiti dal cacao proveniente dalla nostra filiera etica, coltivato in piantagioni sostenibili delle zone più vocate al mondo, e l'abbiamo trasformato in bontà capaci di soddisfare i gusti di chiunque - compresi i vegani e gli intolleranti al glutine, che troveranno tante proposte adatte a loro. Non ci siamo limitati alle semplici tavolette di cioccolato equosolidale bianco, al latte e fondente: le abbiamo arricchite con ingredienti deliziosi e te le proponiamo in diversi formati, comodi anche come snack da portare sempre con te.",
		"h2": "Le tavolette di cioccolato equosolidale Altromercato",
		"desc2": "Preferisci un gusto intenso e croccante? Prova il fondente con quinoa e riso. Ami gli abbinamenti sorprendenti? Allora scegli la tavoletta di cioccolata con curcuma e pepe. Per chi adora la dolcezza esotica abbiamo pensato al cioccolato con latte di cocco, e per chi si sente tradizionalista c'è il nocciolato in versione dark o gianduia. Non abbiamo lasciato da parte nemmeno i palati più esigenti: per loro ci sono le tavolette di cioccolato bianco equosolidale, semplice o con aggiunta di ingredienti originali. Tutte le nostre proposte hanno in comune il fatto di essere buone per tutti: per l'ambiente, per i produttori e per te che le scegli."
	},
	"EC81404": {
		"h1": "Riso e cereali",
		"desc": "Se in cucina ti piace sperimentare e cimentarti nella preparazione di piatti dal sapore esotico, non puoi non provare le diverse qualità di riso etnico di Altromercato. Chicchi di qualità thay, cous cous e quinoa ideali per gustose insalate fredde, in estate, e primi piatti caldi in inverno: un'esplosione di sapori che conquisterà tutti quelli che assaggeranno. Il riso e i cereali equosolidali che noi di Altromercato ti proponiamo provengono da India, Bolivia, Thailandia e altri Paesi nel mondo, e sono prodotti nel pieno rispetto dei lavoratori e dell'ambiente. Perché un'alimentazione sostenibile è un tassello importante per la costruzione di un futuro migliore.",
		"h2": "Riso etnico e cereali Altromercato",
		"desc2": "Tra i cereali equosolidali che trovi nel nostro negozio online, c'è anche la Quinoa Real biologica della Bolivia, considerata tra le più pregiate al mondo. Chicchi tondi e dal sapore pieno, ottimi per arricchire le tue insalate. Il riso etnico thay in tutte le sue varietà - aromatico, integrale, nero e rosso - è perfetto da saltare in padella con una julienne di verdure. E ancora il riso basmati dell'India e il cous cous dalla Palestina, oltre a tanti altri prodotti. Ogni giorno un piatto diverso da servire in tavola, nel pieno rispetto del pianeta."
	},
	"EC85104": {
		"h1": "Sciarpe, cappelli e guanti",
		"desc": "Per affrontare il freddo invernale serve l'abbigliamento adatto. Nella nostra collezione di sciarpe, cappelli e guanti etnici noi di Altromercato abbiamo raccolto una proposta di capi morbidi, caldi e sostenibili per proteggerti da vento e gelo senza rinunciare a fare del bene al pianeta e alle persone che lo abitano. Per realizzare i nostri guanti, le sciarpe e i cappelli solidali abbiamo preso ispirazione dalle tradizioni sartoriali dei piccoli produttori con cui collaboriamo in Africa, Asia e America Latina. La lana incontra quindi fibre naturali come il banano per offrirti un calore e uno stile unici, dal grande fascino esotico.",
		"h2": "Sciarpe, cappelli e guanti per un inverno ecosostenibile",
		"desc2": "Tutti i capi delle collezioni di On Earth - ethical fashion sono realizzati seguendo una filiera di produzione trasparente e priva di qualsiasi tipo di sfruttamento del lavoro. Un impegno che ritrovi anche in questa selezione di accessori invernali pensata per tenerti al caldo con uno spirito equo e solidale. Nei guanti, sciarpe e cappelli etnici di On Earth le linee moderne e semplici si uniscono a colori unici e originali, all'interno di lavorazioni artigianali realizzate con tecniche antiche tramandate da generazioni. Per un'eleganza solidale senza pari."
	},
	"EC81402": {
		"h1": "Salse pomodoro e sughi",
		"desc": "Le nostre salse di pomodoro e i nostri sughi non piacciono ai caporali perché sono realizzati nel pieno rispetto dei diritti dei lavoratori. La produzione delle bontà che trovi nel nostro negozio online si basa sulle relazioni di qualità che costruiamo, giorno dopo giorno, con i produttori. Scegliamo di rispettare il pianeta e il lavoro altrui realizzando salse, pomodoro e sughi biologici in grado di tutelare la biodiversità e la tradizione agricola. Una filiera a basso impatto ambientale che grazie alla lavorazione di materie prime di qualità ci permette di proporti condimenti ricchi di sapore, al giusto prezzo.",
		"h2": "Salse, pomodoro e sughi equosolidali",
		"desc2": "E tu, consumi o scegli? Consumare significa prestare poca cura alle origini delle materie prime e alla loro lavorazione. Vuol dire credere che esista un pianeta B dal quale attingere risorse infinite. Scegliere, invece, è sinonimo di consapevolezza. Perché un futuro migliore dipende soprattutto dal rispetto che portiamo oggi al pianeta. Perché lavorare in condizioni dignitose equivale a lavorare con amore. Questo è quello in cui crediamo e che sentiamo ogni volta che apriamo uno dei condimenti equosolidali che abbiamo selezionato per il nostro negozio online: sughi, pesti e passate di pomodoro pieni di tradizione, dalla qualità unica."
	},
	"EC84001": {
		"h1": "Oggetto bomboniera matrimonio",
		"desc": "Le bomboniere di matrimonio sono un piccolo dono che ricorda agli ospiti un momento speciale. Che siano oggetti decorativi o utili, è sempre bene che raccontino qualcosa degli sposi. Se per te valori come rispetto del lavoro e del pianeta sono importanti, allora puoi scegliere di regalare le bomboniere equosolidali di Altromercato. Dagli accessori per la persona e la casa a piccole stoviglie, dai manufatti in legno a quelli in ceramica: abbiamo selezionato da tutto il mondo gli oggetti che meglio si adattano a questo ruolo, con un particolare occhio di riguardo alla filiera produttiva.",
		"h2": "Le bomboniere equosolidali per matrimonio di Altromercato",
		"desc2": "Per noi di Altromercato è fondamentale proporti bomboniere equosolidali che rispecchino la nostra missione: favorire un mercato giusto, senza sfruttamento né dell'ambiente né delle persone. Ecco perché abbiamo stretto collaborazioni con piccoli artigiani sparsi in tutto il mondo - dalla Thailandia al Perù, passando per Vietnam, Palestina e non solo - in modo da generare un impatto positivo il più vasto possibile. Così che chi, come te, sta per sposarsi e desidera sostenere una buona causa, possa farlo anche nel giorno più importante della sua vita. Con una scelta solo in apparenza piccola, che invece può contribuire a fare la differenza."
	},
	"EC82200": {
		"h1": "Detergere",
		"desc": "Con ogni detergente e tonico viso naturale della linea Natyr di Altromercato, la natura si prende cura della tua pelle. I nostri prodotti per la detersione del viso sono la scelta giusta per una routine di bellezza basata su principi equi e solidali. Mousse, idrogel, struccanti e tonici sono realizzati con piante e fiori provenienti dai più lontani Paesi del mondo, combinati in veri e propri elisir di bellezza, nel pieno rispetto del nostro pianeta.",
		"h2": "Detergenti e tonici per il viso Natyr Altromercato",
		"desc2": "Dalla pelle secca a quella matura, senza dimenticare quella più sensibile: la natura offre sempre un rimedio in grado di prendersi cura del tuo viso. Ogni nostro detergente viso naturale o tonico viso certificato è realizzato nel totale rispetto dell'ambiente e dei lavoratori che fanno parte della filiera di produzione. Dal tè verde all'argan, passando per l'ibisco e l'aloe verde: non c'è terra del nostro mondo che non abbia messo a disposizione i suoi frutti per il benessere del tuo corpo. E noi di Altromercato li abbiamo scelti con cura, e con il desiderio di proteggerli: perché della difesa della biodiversità abbiamo fatto una missione."
	},
	"EC81401": {
		"h1": "Olio",
		"desc": "Un filo d'olio a crudo rende speciale ogni piatto. l'olio giusto, poi, è in grado di esaltare qualsiasi sapore e trasformare anche una semplice insalata in un pasto da ricordare. Per la nostra selezione di oli noi di Altromercato collaboriamo con piccole realtà che hanno fatto della valorizzazione delle materie prime e delle tecniche di produzione i propri punti di forza. In ogni bottiglia di olio extravergine d'oliva del nostro negozio online è custodita una storia di passione per la terra e di scelte coraggiose per portare anche in tavola la voglia di cambiamento e l'impegno a costruire un mondo più giusto e solidale.",
		"h2": "Oli artigianali prodotti con tecniche sostenibili",
		"desc2": "La proposta di olio EVO che noi di Altromercato abbiamo preparato per il nostro shop online unisce la qualità degli ingredienti con lavorazioni etiche e sostenibili. Sono prodotti che nascono in zone di resistenza alla criminalità organizzata. Sono uliveti e frantoi che raccontano storie di coraggio e lotta per la legalità. Sono la vita di persone che conoscono il valore del sacrificio e della rinuncia. Da queste grandi esperienze di impegno e resistenza nasce un olio di oliva artigianale che rispetta i procedimenti di produzione tradizionale per valorizzare le materie prime ed esaltare il sapore del condimento principe della dieta mediterranea."
	},
	"EC85004": {
		"h1": "Camicie",
		"desc": "Scegli il fascino di un'eleganza esotica dalla grande personalità con le camicie etniche On Earth - ethical fashion Altromercato. Una selezione di capi raffinati e allo stesso tempo comodi per portare un tocco etno-chic e solidale nel tuo look. Tutti i nostri capi, infatti, sono realizzati seguendo una filiera sostenibile e rispettosa del lavoro delle persone coinvolte nel processo di produzione. Con le nostre camicie da donna ecosostenibili, noi di Altromercato miriamo a proporre una nuova idea di stile. Una bellezza che unisce i migliori filati naturali con un design dalle linee moderne realizzate al telaio, a mano, secondo tecniche sartoriali e decorative tradizionali.",
		"h2": "Una camicia etnica per il tuo stile",
		"desc2": "Le bluse e le camicie etniche della linea On Earth - ethical fashion sono realizzate in collaborazione con i nostri produttori partner provenienti da ogni angolo del pianeta. Asia, Africa e America Latina incontrano così realtà italiane ed europee che hanno fatto del lavoro etico il cardine della propria organizzazione. Il confronto continuo con i partner con cui lavoriamo ci aiuta a selezionare i cotoni e le materie prime più raffinate per far risaltare la tua eleganza. Con le camicie ecosostenibili di On Earth di Altromercato porti nel tuo guardaroba un look etnico raffinato, senza rinunciare al comfort di una vestibilità comoda in ogni momento della giornata."
	},
	"EC83103": {
		"h1": "Mug e tisaniere",
		"desc": "Le mug artigianali della collezione On Earth - ethical home di Altromercato scaldano mani e cuore. Se tenerle strette conforta i momenti più tiepidi, farle tue rappresenta una scelta consapevole per sostenere il commercio sostenibile. Le decorazioni geometriche o esotiche tramandano la lunga tradizione artigianale della lavorazione della ceramica in Paesi come Thailandia, Vietnam e molti altri. Con motivi finemente realizzati a mano sulla superficie delle tisaniere artigianali, ogni piccolo momento di benessere rappresenterà una gioia condivisa fra tutti: produttori locali, ambiente e consumatori responsabili come te.",
		"h2": "Mug e tisaniere artigianali Altromercato",
		"desc2": "La filiera trasparente è la garanzia di qualità che sta alla base dei nostri prodotti, incluse tisaniere e mug artigianali Altromercato. Con le nostre aromatiche tisane danno il meglio, ma possono tenerti compagnia anche durante la pausa caffè o mentre sorseggi un tè pomeridiano. Ogni modello ha le sue unicità e un significato tutto da scoprire, tra simbologie orientali e decorazioni antiche o moderne. Scegli la fantasia che più si abbina al tuo gusto e goditi un momento di relax, promuovendo sempre lo sviluppo di un commercio più giusto in tutto il mondo."
	},
	"EC85000": {
		"h1": "Abiti",
		"desc": "Abiti etnici da donna, sostenibili ed equosolidali? Li trovi sul negozio online di Altromercato. La moda etica per lei del marchio On Earth - ethical fashion rappresenta il connubio perfetto tra stile e consumo consapevole, per te che hai deciso di dire basta al fast fashion ma non hai intenzione di rinunciare a un look curato nei minimi dettagli. Cotone organico al 100%, design moderno e comodità sono le tre caratteristiche che accomunano tutti i nostri capi d'abbigliamento, insieme all'ecocompatibilità delle materie prime e al rispetto dei diritti dei lavoratori, dall'inizio alla fine della filiera di produzione.",
		"h2": "La moda donna ecosostenibile di Altromercato",
		"desc2": "Ogni abito da donna etico di On Earth - ethical fashion Altromercato è realizzato con estrema cura dei particolari. Dagli abiti più eleganti a quelli pratici: quelli che trovi nel nostro negozio online sono capi destinati a durare a lungo, comodi e perfetti per la vita di tutti i giorni. La vestibilità, estremamente regolare, rende i nostri abiti etnici da donna versatili e adatti a ogni fisicità. Se ami la moda e hai a cuore il rispetto dell'ambiente non potrai che appassionarti le nostre proposte d'abbigliamento, prodotte da realtà artigianali e responsabili."
	},
	"EC81403": {
		"h1": "Pasta",
		"desc": "Nella nostra selezione di pasta - di quinoa e non solo - noi di Altromercato abbiamo reso possibile l'incontro fra la tradizione italiana dei primi piatti e le qualità naturali di ingredienti esotici come la quinoa. Originaria del Sud America, dove viene coltivata in modo etico dai produttori della nostra filiera sostenibile, si abbina alla semola di grano duro per un pasto gustoso e nutriente. Se la lenta essiccazione fino a 20 ore garantisce una cottura ottimale, la qualità delle materie prime saprà conquistare il tuo palato senza appesantire la digestione. Per un apporto energetico ideale, scegli uno dei nostri sughi come condimento e avrai sempre un menù ricco di nutrienti. Poi lascia decidere il tuo gusto: meglio una pasta di quinoa corta o gli spaghetti biologici?",
		"h2": "La storia antica della pasta di quinoa Altromercato ",
		"desc2": "Gli effetti salutari della quinoa erano noti anche in tempi antichi quando gli Inca la consideravano sacra e, venerandola, la chiamavano \"madre di tutti i semi\". Oggi che conosciamo la sua importanza, per proteggere la biodiversità dell'area andina è essenziale favorire una coltivazione sostenibile. Con la nostra produzione equa e solidale, noi di Altromercato manteniamo fede al nostro impegno con il pianeta affidandoci a produttori etici che proteggono la terra ed evitano lo sfruttamento delle coltivazioni. Al resto pensa la nostra logistica responsabile che trasporta le qualità nutritive della pasta, anche quella di quinoa, fino a casa tua."
	},
	"EC82100": {
		"h1": "Shampoo",
		"desc": "Shampoo alle erbe, shampoo naturale, shampoo solido o liquido: nella linea Natyr di Altromercato c'è tutto quello che può servire per rispondere alle tue esigenze. Ti offriamo una perfetta combinazione di ingredienti delicati a base naturale e dei valori etici a cui da sempre diamo tanta importanza. Gli shampoo che ti proponiamo sfruttano tutte le proprietà della natura, rispettando la tua salute e l'ambiente da cui provengono. E anche quello in cui finiranno, grazie a un packaging spesso riciclato e al 100% riciclabile. I tuoi capelli saranno più sani che mai, e con loro anche il nostro pianeta.",
		"h2": "Lo shampoo naturale e solidale di Natyr Altromercato",
		"desc2": "Oltre a porre grande attenzione alla scelta degli ingredienti per i nostri shampoo naturali, noi di Altromercato ci assicuriamo anche che i processi della filiera siano equi e solidali per tutte le persone coinvolte. Dal Marocco alla Thailandia, passando per l'India: i luoghi di coltivazione e produzione degli shampoo Natyr toccano molti angoli del mondo. Lì andiamo a cercare il meglio della sapienza artigiana locale e garantiamo equità di lavoro e di compenso. Perché ogni shampoo naturale che acquisti non si riveli solo un ottimo detergente per i tuoi capelli, ma anche una scelta consapevole capace di fare la differenza, nel suo piccolo."
	},
	"EC81301": {
		"h1": "Miele e confetture",
		"desc": "Insieme ai nostri produttori etici, noi di Altromercato conserviamo per te un po' di naturale dolcezza in ogni barattolo di miele e confettura biologica della nostra selezione. A te la scelta di come gustare la loro bontà. Potrai apprezzare il delizioso sapore dell'agricoltura sostenibile spalmato sul pane croccante, come guarnizione di un dolce o per accompagnare il tuo formaggio stagionato preferito. E a proposito di doni che ci riserva la terra, il nostro miele biologico - che sia monoflora o millefiori - non viene mai pastorizzato. In questo modo manteniamo intatte tutte le sue proprietà benefiche. Dolci sì, ma senza aggiunte artificiali: assaggia anche la composta di frutta bio per assaporare soltanto gli zuccheri della frutta.",
		"h2": "Miele e confettura biologica Altromercato",
		"desc2": "Il commercio equo e solidale e un'alimentazione sana possono diventare gli ingredienti segreti per il cambiamento. Consumare con maggiore consapevolezza, rispettare il normale ritmo della produzione della terra e riscoprire i tesori nascosti del mondo: noi di Altromercato abbiamo a cuore tutte queste missioni. È la ragione per cui ogni composta di frutta biologica aiuta a creare prosperità economica. E ciascun vasetto di miele, bio o meno, è tanto delicato quanto importante nella difesa dell'ambiente. Le tue scelte contano: rendile sostenibili."
	},
	"EC82002": {
		"h1": "Deodoranti",
		"desc": "Porta la natura sulla tua pelle con i deodoranti di Natyr Altromercato. Una linea di prodotti pensata per accompagnarti per tutta la giornata con un'azione delicata e resistente in ogni situazione. La formulazione naturale dei nostri deodoranti è studiata per rispettare l'equilibrio del tuo corpo e i suoi processi fisiologici, utilizzando ingredienti equi e solidali lavorati nel rispetto dell'ambiente. Ogni deodorante ha una composizione specifica per soddisfare una vasta gamma di esigenze con principi attivi naturali ed ecosostenibili. Anche una scelta apparentemente piccola come quella del deodorante può fare la differenza per un futuro più verde e un lavoro più equo per tutti.",
		"h2": "I prodotti per l'igiene personale consapevole di Altromercato",
		"desc2": "La composizione dei deodoranti Natyr è formulata e lavorata con cura dai nostri partner, una rete di produttori che condivide con noi l'idea di una cosmetica rispettosa dell'ambiente e dei diritti delle persone coinvolte nella filiera di produzione. Dalla raccolta delle materie prime al confezionamento, i deodoranti che noi di Altromercato ti proponiamo sono studiati per tutelare il pianeta con tecniche consapevoli che non alterano i cicli naturali e rispettano i diversi ecosistemi in cui coltiviamo i nostri ingredienti. Ogni deodorante racchiude una storia di impegno per un mondo più sostenibile e consumi più consapevoli."
	},
	"EC85105": {
		"h1": "Stole e foulard",
		"desc": "Nelle collezioni On Earth - ethical fashion c'è spazio per qualsiasi accessorio di tendenza, proprio come le stole e i foulard etnici da produzione sostenibile. Le sfumature dei tessuti che abbiamo selezionato per te sono un incanto, fantastici dettagli che impreziosiscono ogni coprispalla. Avvolti attorno al collo sapranno arricchire la morbidezza di una giacca o di un abito. Qualsiasi stile tu voglia sfoggiare, la tua scelta responsabile avrà un forte impatto nel sostenere un'economia davvero giusta. In un futuro dove ci impegneremo sempre più a favorire il commercio equo e solidale, le tradizioni secolari degli artigiani del mondo sono già pronte a conquistare il tuo guardaroba. ",
		"h2": "Le stole e i foulard equosolidali di Altromercato",
		"desc2": "Lo stile che noi di Altromercato perseguiamo è diverso, quasi rivoluzionario. Il merito va alla filiera trasparente e responsabile nei confronti dei produttori e delle loro realtà. Se stole e foulard etnici contribuiscono a creare prosperità per le comunità locali, il nostro progetto di economia circolare fa il massimo per recuperare le giacenze dei magazzini. Quando si tratta di consumi consapevoli ogni tessuto è un piccolo tesoro: riciclare non è mai stato tanto alla moda. "
	},
	"EC63000": {
		"h1": "Casa",
		"desc": "Le creazioni artigianali On Earth - ethical home portano in casa tua tutto l'amore per la sostenibilità di Altromercato. Lasciati ispirare da tante idee regalo per creare ambienti calorosi e accoglienti: ravviva il tuo arredamento con le decorazioni prodotte secondo i principi del mercato equo e solidale. Vasi dai mille utilizzi, diffusori per inebriarti con i profumi di paesi lontani, stoviglie e tessili per la cucina realizzati sostenendo gli artigiani di tutti i continenti e molto altro. Ci piace pensare a un pianeta migliore per tutti: un cambiamento che inizia già a casa tua.",
		"h2": "Oggetti sostenibili per una casa ricca di emozioni",
		"desc2": "Il tuo nido racconta di te più di quanto tu possa immaginare. Viaggi, ricordi, passioni e i valori in cui credi: la tua storia è impressa sui muri come una fotografia. Gli oggetti e le decorazioni On Earth - ethical home diventano nuove pagine in cui raccontarti e descrivere un mondo differente. Un luogo in cui le persone e la loro dignità sono al primo posto e dove la prosperità è condivisa per distribuire valore in modo più equo. Basta una candela profumata Altromercato per accendere un cambiamento davvero sostenibile."
	},
	"EC81503": {
		"h1": "Sciroppi",
		"desc": "Che sia estate o inverno, gli sciroppi equosolidali Altromercato non devono mai mancare nella dispensa della tua cucina per creare ricette sempre nuove. Cerchi ispirazione? Per gustare una bevanda rinfrescante esplora i segreti del Guaranà dell'Amazzonia e sperimenta il suo effetto energizzante. Non solo: i nostri sciroppi possono essere l'ingrediente segreto per aromatizzare un dolce fatto in casa con un gusto che viene da lontano. Il sapore del commercio equo e solidale è buono per te, per l'ambiente e per tutte le persone coinvolte nella filiera produttiva.",
		"h2": "Sciroppi che rispettano l'ambiente",
		"desc2": "Ogni estratto che ti proponiamo racchiude una componente ancora più dolce del suo sapore: la sostenibilità Altromercato. Quando si tratta di creare opportunità negli angoli più lontani del pianeta ci sono molti elementi a cui prestare la massima attenzione, e la produzione dei nostri sciroppi li rispecchia appieno. La nostra missione ci spinge a proteggere la biodiversità delle coltivazioni nel più rigoroso rispetto per l'ambiente in cui vivono i produttori locali. Garantire prosperità alle loro comunità, poi, sottolinea con forza l'idea rivoluzionaria di mercato che da oltre 30 anni portiamo avanti. Assapora il nostro impegno in queste bevande: dissetati con la bontà ed ecosostenibilità così cara a noi di Altromercato. "
	},
	"EC85201": {
		"h1": "Bracciali",
		"desc": "I bracciali etnici della collezione On Earth - ethical fashion di Altromercato, gioielli unici nel loro genere, hanno tutto ciò che serve a soddisfare la tua voglia di bellezza. Tra perline in legno, catenine in metallo e ciondoli ricchi di dettagli puoi regalarti gioielli di alta qualità realizzati da artigiani che condividono la nostra idea di mercato solidale. Innamorati dei particolari orientali e divertiti ad abbinare le coloratissime trame decorative con i tuoi capi d'abbigliamento preferiti: con i nostri bracciali etnici puoi sostenere il Commercio Equo e Solidale.",
		"h2": "I bracciali etnici di Altromercato",
		"desc2": "I tuoi acquisti, anche i più piccoli, possono generare un impatto positivo nel mondo. Scegliendo i nostri bijoux artigianali ci aiuti a portare avanti una rivoluzione fatta di rispetto, prosperità condivisa e fiducia. Il commercio che ci impegniamo a sostenere genera effetti positivi che coinvolgono te, mentre indossi i nostri bracciali etnici, i produttori locali, che hanno l'occasione di mostrare la loro lunga tradizione artistica, e il mercato nella sua totalità. Crea insieme a noi un futuro più giusto: l'unica strada è compiere scelte sostenibili nel pieno rispetto degli altri e del pianeta che ci ospita. "
	},
	"EC81000": {
		"h1": "Caffè e orzo",
		"desc": "Esiste un caffè che non è amaro per chi lavora? Sì, e nasce in una filiera ad alto impatto sociale. La relazione di fiducia, trasparenza e continuità che abbiamo stabilito con i nostri produttori selezionati di caffè e orzo è alla base della realizzazione dei prodotti che trovi nel nostro negozio online. Ci impegniamo affinché i diritti dei lavoratori vengano rispettati, perché il caffè venga pagato loro a un giusto prezzo, e ci adoperiamo perché biodiversità e territorio vengano tutelati, sempre. Il processo di lavorazione e vendita del caffè che sosteniamo è solidale e rispettoso del pianeta. Il risultato che offriamo a te sono bevande dal sapore unico e tradizionale.",
		"h2": "Caffè e orzo: le bevande calde di Altromercato",
		"desc2": "I nostri chicchi di caffè sono raccolti a mano, uno a uno, nel pieno rispetto del processo di maturazione delle piante. Le coltivazioni crescono all'ombra di alberi che proteggono il suolo dall'erosione, per preservare la biodiversità. Nessuna coltivazione intensiva, nessuna sostanza chimica. Solo tanto sole e la tradizione agricola di popoli che hanno fatto della produzione e vendita di caffè la loro principale fonte di sostentamento. Le nostre miscele sono fragranti e dal gusto intenso, e abbiamo affidato la loro protezione a un imballaggio completamente riciclabile. E tu, consumi o scegli?"
	},
	"EC85005": {
		"h1": "Gonne",
		"desc": "Vestire alla moda rispettando i principi etici del commercio equo e solidale è possibile, grazie alla linea di gonne On Earth - ethical fashion di Altromercato. Attraverso le sapienti mani degli artigiani con cui collaboriamo, ogni tessuto prende vita e assume una forma unica. Ti proponiamo gonne etniche realizzate con stoffe preziose nella loro semplicità, abbellite da stampe e colori capaci di soddisfare i gusti più vari. Oppure gonne ecosostenibili, in cui vecchi pantaloni e tessuti inutilizzati acquistano un'identità tutta nuova per trasformarsi in originalissimi capi femminili. In una riuscita combinazione fra tradizioni lontane e design Made in Italy, troverai ciò che può rendere inimitabile il tuo guardaroba.",
		"h2": "Le gonne etniche e sostenibili On Earth - ethical fashion",
		"desc2": "Siamo circondati di doni, che spesso fatichiamo a vedere ma che racchiudono in sé grande valore. Come i materiali di recupero che noi di Altromercato - insieme agli artigiani con cui lavoriamo - abbiamo deciso di utilizzare per creare le nostre gonne sostenibili. O come i tessuti che raccogliamo da Paesi lontani del mondo per trasformarli in capi d'abbigliamento etnico adatti a ogni occasione. Ogni gonna ecosostenibile della collezione On Earth rispetta l'ambiente e il lavoro di chi la produce. Vestiti di una nuova bellezza: estetica, ma anche etica, a sostegno di una nuova idea di moda."
	},
	"EC83102": {
		"h1": "Teiere e tazze da tè",
		"desc": "Quando le temperature si rinfrescano e i pomeriggi volgono presto al tramonto, c'è forse qualcosa di più bello che godersi un bel tè aromatico sul divano, magari in compagnia di un libro? Ecco che a rendere perfetto il momento arrivano le teiere e le tazze artigianali di Altromercato. Tra colori e fantasie, tra capienze e forme diverse, non ti resta che scegliere le tue preferite per dare il via al rituale del tè. Un rituale più buono che mai, perché sostiene in pieno i principi del commercio equo e solidale che noi di Altromercato promuoviamo da sempre.",
		"h2": "Teiere e tazze artigianali uniche al mondo, con Altromercato",
		"desc2": "Far realizzare le nostre teiere e tazze da tè a piccoli artigiani significa poterti offrire dei prodotti davvero unici, realizzati a mano seguendo tradizioni lunghe secoli. In questo modo sosteniamo, inoltre, una filiera equa e solidale, che genera un impatto positivo sulla vita delle persone. Il tuo rituale di benessere e calore quotidiano porterà con sé i sentori di Paesi lontani, come la Thailandia o il Nepal. Che tu decida di comprare per te o di regalare le nostre tazze e teiere artigianali, sappi che stai facendo una scelta sostenibile e di grande valore."
	},
	"EC81700": {
		"h1": "Panettoni e pandori",
		"desc": "Nel periodo natalizio, panettoni e pandori non mancano mai sulle tavole degli italiani. Chi preferisce i primi e chi i secondi, chi li vuole coi canditi e chi no, chi va sul tradizionale e chi invece punta sulla creatività: siamo tutti diversi ma, in un modo o nell'altro, tutti amiamo questi dolci delle feste. Noi di Altromercato abbiamo pensato di proporti pandori e panettoni equosolidali, che racchiudono l'eccellenza della pasticceria italiana e i sapori esotici di Paesi lontani. Zucchero, cioccolato e uvetta provengono dalle nostre filiere etiche, ormai ben radicate in territori dell'Africa e delll'America Latina. ",
		"h2": "I pandori e panettoni equosolidali di Altromercato",
		"desc2": "Nel proporti i nostri panettoni e pandori naturali, gustosi ed etici abbiamo pensato proprio a tutto. Abbiamo selezionato ingredienti equosolidali per un'ottima base di partenza e le migliori pasticcerie per una produzione artigianale che sapesse come esaltarli. Infine abbiamo reso i nostri prodotti anche belli, avvolgendoli in preziosi teli in carta-seta dipinti a mano in Bangladesh. In questo modo le nostre prelibatezze sono ottime da gustare e perfette da regalare alle persone che ami di più. Apprezzeranno di certo un dono che combina fascino esotico, tradizioni italiane e rispetto per tutti i lavoratori coinvolti nella filiera produttiva."
	},
	"EC71600": {
		"h1": "Integratori",
		"desc": "Gli integratori naturali equosolidali che noi di Altromercato abbiamo selezionato per te sono pensati per sfruttare appieno il potere della natura. Ognuno di essi svolge un ruolo preciso per sostenerti al meglio durante la giornata. Da quello che rinforza il sistema immunitario grazie alle vitamine, al ricostituente naturale a base di guaranà nativo, passando per superfood come maca e spirulina: siamo certi che tra le nostre proposte troverai l'integratore alimentare naturale giusto per te. Molti di essi sono anche vegani e senza glutine, per andare incontro alle esigenze di tutti. Come riconoscerli? Basta che ti affidi ai bollini con la V e la spiga barrata. ",
		"h2": "Gli integratori naturali ed equosolidali di Altromercato",
		"desc2": "Alcuni periodi sono particolarmente faticosi e stressanti per il nostro corpo: in quei momenti è utile avvalersi di un aiuto esterno. Un aiuto come quello che possono fornire degli integratori alimentari naturali: compresse, sciroppi o polveri da sciogliere, e da assumere come sostegno quotidiano. E quando la vita si fa davvero frenetica risulta molto comodo poter acquistare gli integratori online. Ancor meglio, poi, se sono di qualità elevata e anche equosolidali, come i nostri. Noi di Altromercato abbiamo selezionato le migliori materie prime da tutto il mondo, e le abbiamo trasformate in piccoli ma potenti alleati. Sempre nel rispetto dell'ambiente e del lavoro dei produttori."
	},
	"EC82201": {
		"h1": "Creme e trattamenti",
		"desc": "Con i prodotti Natyr di Altromercato, c'è un mondo intero a prendersi cura della tua bellezza in modo naturale. La nostra linea di trattamenti e creme per la cura del viso sarà la tua routine di bellezza equa e solidale. Creme per il viso e il contorno occhi, sieri, maschere e balsami contengono tutti i principi attivi benefici che la natura ci offre. E sono sempre prodotti secondo i valori per cui chi crede in un commercio sostenibile sceglie noi di Altromercato: rispetto del pianeta e dei diritti dei lavoratori. ",
		"h2": "Le creme idratanti per il viso di Altromercato",
		"desc2": "Qualsiasi bisogno abbia la tua pelle, in natura esiste un estratto o un olio capace di darvi sollievo. Idratante, lenitiva, antiossidante o purificante: devi soltanto scegliere una fra le creme per la cura del viso prodotte all'interno di una filiera differente, come quella che portiamo avanti nella nostra missione. I produttori che abbiamo selezionato in tutto il mondo - dall'India al Marocco, e non solo - sanno come ottenere il massimo dalla natura, per favorire il benessere della tua pelle. Noi di Altromercato sosteniamo da sempre le loro coltivazioni tradizionali proteggendo la biodiversità, per garantire prosperità economica ai produttori locali e assicurare a te un'esperienza sensoriale senza pari."
	},
	"EC72300": {
		"h1": "Corpo",
		"desc": "La scelta dei prodotti per la cura del corpo può rappresentare un punto di partenza per un consumo più consapevole ed ecosostenibile. La linea per il corpo di Natyr Altromercato unisce ingredienti naturali, come oli essenziali e burri vegetali selezionati e raccolti con cura in ogni angolo del pianeta, con una lavorazione attenta e rispettosa dell'ambiente e delle persone coinvolte nella produzione. Le nostre formulazioni esclusive si ispirano a tradizioni millenarie di bellezza, e combinano saperi antichi nati da una stretta conoscenza della terra con formule di produzione innovative.",
		"h2": "Il benessere naturale ecosostenibile Natyr Altromercato",
		"desc2": "La gamma di prodotti per il corpo naturali di Altromercato viaggia tra l'India e il Marocco, la Thailandia e il Perù. Sono solo alcuni dei paesi che abbiamo esplorato per selezionare per te gli ingredienti migliori, per dei trattamenti di benessere che portino sul corpo di chi li prova tutta l'energia della natura. I profumi di piante e fiori si uniscono in formulazioni delicate sulla pelle per nutrire e idratare, e riunire idealmente il corpo con il suo mondo di appartenenza: la natura. Le creme corpo naturali, le creme mani e le lozioni che noi di Altromercato ti proponiamo nel nostro shop sono pensate per rispettare il tuo equilibrio fisiologico con preparazioni naturali e prive di additivi chimici, nel pieno rispetto delle risorse del pianeta."
	},
	"EC81305": {
		"h1": "Crackers, gallette e taralli",
		"desc": "Gustosi, genuini e rigorosamente equosolidali: noi di Altromercato adoriamo farti assaggiare tutta la bontà della natura. Per uno spuntino dal sapore solidale non c'è nulla di meglio di crackers, gallette e taralli dal sapore autentico, prodotti secondo ricette originali. Gli ingredienti semplici e naturali della dieta mediterranea - come olio extra vergine d'oliva e lievito madre - incontrano i sapori orientali in una combinazione croccante e fragrante. Per mantenere una buona alimentazione c'è bisogno di equilibrio fra proprietà nutritive e sapori vivaci. Che per noi vuol dire portare a tavola il meglio della natura, rispettando l'ambiente per un futuro davvero sostenibile.",
		"h2": "Crackers, gallette e taralli naturalmente buoni",
		"desc2": "Invece di chiedere al nostro pianeta uno sforzo senza sosta, crediamo sia giusto prima dargli qualcosa in cambio. Non ci riferiamo soltanto all'impegno nel favorire trasporti responsabili e confezioni riciclabili, ma soprattutto nel sostenere le coltivazioni etiche che non impoveriscono i terreni. Lo facciamo per garantirti un futuro più verde e farti assaggiare già oggi prodotti buoni e salutari. l'idea che noi di Altromercato abbiamo del commercio è molto precisa, e scegliendo questi crackers, gallette e taralli puoi partecipare insieme a noi alla rivoluzione equa e solidale."
	},
	"EC73100": {
		"h1": "Stoviglie, tazze e piatti",
		"desc": "Una selezione di stoviglie in ceramica artigianale realizzate per noi di Altromercato da mani esperte in ogni angolo del mondo. Nel nostro catalogo di piatti, tazze, tazzine da caffè, teiere, vassoi e molto altro trovi tutto quello che può servirti per apparecchiare la tavola e arredare la casa con gusto e un occhio attento a un consumo sostenibile. Tutte le nostre stoviglie artigianali sono fabbricate da produttori indipendenti che uniscono nelle proprie opere tradizioni locali e una scrupolosa ricerca delle migliori materie prime.",
		"h2": "Le stoviglie artigianali di Altromercato per la tua casa",
		"desc2": "Noi di Altromercato abbiamo scelto di proporti le migliori stoviglie per ispirarti con soluzioni originali per la tua casa o per fare un regalo a una persona cara. Scegliere un complemento di arredo artigianale dal sapore etnico trasforma ogni ambiente con colore e personalità e riempie la tavola di stile e di storia. Acquistare da produttori indipendenti vuol dire fare una scelta consapevole e responsabile nei confronti dell'ambiente e di tutte le persone coinvolte nella lavorazione. Per noi di Altromercato tutelare i diritti dei lavoratori e delle lavoratrici è tanto importante quanto trovare idee sempre nuove e interessanti per contribuire a migliorare la tua vita in modo equo e solidale."
	},
	"EC85203": {
		"h1": "Orecchini",
		"desc": "Per completare il tuo look del giorno è sufficiente scegliere gli accessori giusti o delle piccole coccole preziose, uniche proprio come te. Gli orecchini etnici della linea On Earth - ethical fashion di Altromercato sono il tocco originale in più che stai cercando. Pietre dure e piume, unite all'ottone argentato o dorato, si fondono per dare vita a orecchini pendenti, a goccia o a bottone perfetti per ogni stile ed età. Decorazioni orientali e linee minimal si alternano in collezioni di gioielli artigianali provenienti da tutto il mondo, realizzati nel pieno rispetto del pianeta e dei diritti dei lavoratori.",
		"h2": "Gli orecchini etnici di Altromercato",
		"desc2": "Il nostro futuro dipende dai piccoli gesti che compiamo ogni giorno. E anche le nostre decisioni in fatto di moda e accessori possono avere un forte impatto sull'ambiente che ci circonda. Per questo noi di Altromercato abbiamo scelto di lasciare che i nostri ideali di rispetto e solidarietà invadano anche questo mondo. Gli orecchini etnici On Earth - ethical fashion sono un esempio di come stile e sostenibilità possano viaggiare insieme, fino al tuo portagioie. Indossare una delle creazioni che abbiamo selezionato per te, oltre a impreziosire il tuo look, sarà un importante passo sulla strada del commercio alternativo."
	},
	"EC81502": {
		"h1": "Bevande analcoliche",
		"desc": "Bere bevande analcoliche equosolidali fa bene a chi le consuma e a chi le produce. Per te che le servi c'è la garanzia di materie prime di qualità e di un prodotto gustoso, senza eguali. Per chi le produce c'è il rispetto dei diritti dei lavoratori all'interno di una filiera di produzione etica e sostenibile, che tiene conto della biodiversità. Il tuo benessere e quello del pianeta passano anche per le scelte che compi a tavola. Dall'antipasto alle bibite, ogni prodotto servito può fare la differenza, verso un futuro migliore e uno stile di vita equo e solidale.",
		"h2": "Le bevande analcoliche equosolidali",
		"desc2": "Dall'Italia alle Mauritius, dal Cile al Paraguay: le bibite di qualità mettono d'accordo tutti. Perfette per gli aperitivi in compagnia o per aggiungere un po' di gusto frizzante ai tuoi pasti, le bevande analcoliche di Altromercato sono ciò che manca alla tua tavola. La soda al ginger, per esempio, ha un elevato potere dissetante e con l'aggiunta di succo di limone o arancia, vino bianco e ghiaccio renderà il tuo spritz più esotico. O ancora la cola biologica, senza caffeina e aromi artificiali, capace di conquistare grandi e piccini. Nel nostro negozio online soddisfiamo i gusti più disparati, in maniera equa e solidale."
	},
	"EC81001": {
		"h1": "Tè",
		"desc": "I benefici del tè nero e le proprità antiossidanti del tè verde sono ideali per ritagliarti un momento di profonda tranquillità. Per dare vita a questa ricercata selezione di tè equo e solidale, noi di Altromercato lavoriamo a stretto contatto con i produttori locali delle aree del mondo dove tradizionalmente si raccolgono le migliori foglie della camellia sinensis. Aromi profondi e sapori coinvolgenti per tè pregiati, anche deteinati, che raccontano la storia di una pianta salutare che cresce alle pendici dell'Himalaya, in India, nello Sri Lanka e in molti altri luoghi. Decidi ogni giorno cosa sorseggiare: sarà un tè nero aromatizzato all'arancia per colazione o un fresco e speziato tè verde con zenzero e limone?",
		"h2": "Tè nero e tè verde Altromercato ",
		"desc2": "Scegliendo uno fra i nostri prodotti e cofanetti ci aiuti a dare un'opportunità di sviluppo alle comunità locali che fondano la propria economia sulla coltivazione etica delle piante di tè. Con il commercio equo che noi di Altromercato favoriamo, puoi provare direttamente a casa tutti i benefici di un tè nero coltivato senza sfruttamento. Condiviso con chi vuoi, poi, sarà ancora più piacevole: sostenere la nostra missione è infatti un'ottima occasione per un regalo solidale e rispettoso dell'ambiente. Una volta preparato l'infuso e finito di gustare un tè, nero o verde, potrai dare nuova vita alla confezione: i cofanetti in latta o in fibre naturali conservano l'aroma delle foglie sfuse e possono diventare dei perfetti porta tè."
	},
	"EC85202": {
		"h1": "Collane",
		"desc": "Tra i bijoux che noi di Altromercato ti proponiamo compaiono le collane etniche della collezione On Earth - ethical fashion. Gioielli dall'elevato valore intrinseco, realizzati in maniera artigianale e nel pieno rispetto dei principi che da sempre ci muovono. Da oltre trent'anni sosteniamo e alimentiamo un mercato equo e solidale, per generare un impatto positivo ovunque ce ne sia bisogno - dal nostro Paese al resto del mondo. Anche le nostre collane etniche contribuiscono a questa causa, che puoi scegliere di sostenere e che andrà a esaltare il tuo buon gusto per la moda.",
		"h2": "Le collane etniche On Earth - ethical fashion",
		"desc2": "Catenine con pietre, perline o pendenti in metallo decorati con stile e originalità, oppure cordoncini in cotone abbelliti da elementi in legno e tagua: nel nostro shop puoi trovare le collane etniche che più rispecchiano la tua personalità e il tuo modo di vestire. Abbinale agli altri accessori artigianali e ai capi d'abbigliamento unici della linea On Earth - ethical fashion, per un risultato che ti farà sentire in pace col mondo. In parte perché racconterà chi sei a partire da piccoli dettagli, e in parte perché sosterrà i principi di commercio equo e solidale così importanti per te e per noi di Altromercato. "
	},
	"EC83201": {
		"h1": "Tovaglie e tovagliette",
		"desc": "Con le tovaglie artigianali e le tovagliette in cotone On Earth - ethical home di Altromercato l'arredo di casa si arricchisce con la bellezza dei tessuti del mondo. Nel pieno rispetto dei nostri valori di sostenibilità sociale, ti diamo l'occasione di scoprire il prezioso lavoro che svolgono gli artigiani in diverse parti del mondo. Ogni tovaglia in cotone è intrecciata a mano restando fedele a una tradizione che resiste da secoli. E la filiera trasparente si assicura che arrivi a casa tua garantendo sempre prosperità ai produttori locali, nella totale attenzione all'equilibrio ecologico dei Paesi di produzione. Così puoi farti conquistare da un runner nepalese o scegliere una fra le tovagliette artigianali Altromercato, sapendo di favorire un commercio più giusto nel mondo.",
		"h2": "Tovaglie e tovagliette in cotone ricche di significato",
		"desc2": "Per te o per regalare una finissima creazione manuale, il messaggio che noi di Altromercato vogliamo trasmettere non cambia: la sostenibilità si fa insieme. Grazie alle tovagliette e tovaglie artigianali On Earth - ethical home puoi creare ambienti ricchi di colore e personalità senza impattare sull'ambiente. Il segreto è scegliere il commercio equo e solidale e circondarti delle meraviglie del mondo per dare vita a un futuro dove i benefici siano davvero per tutti."
	},
	"EC81407": {
		"h1": "Cocco e farine",
		"desc": "Per realizzare impasti, preparare creme e cucinare ricette elaborate ci sono ingredienti che non devono mancare mai in cucina. Con i derivati del cocco e le farine - per dolci e non solo - che noi di Altromercato ti proponiamo hai tutto il necessario per organizzare una cena dal sapore equo e solidale. Ad esempio, chi ama le note esotiche del cocco può scegliere fra una selezione di prodotti genuini e naturali. Con la farina di cocco, le scaglie grattugiate e il latte ottenuto dalle coltivazioni biologiche thailandesi puoi sfornare i dolci della nostra tradizione e ricreare anche squisite ricette orientali. ",
		"h2": "Farina di cocco, integrale e per dolci Altromercato ",
		"desc2": "Commercio equosolidale ma non solo: una missione che a noi di Altromercato sta particolarmente a cuore è dare vita a un mercato rispettoso dell'ambiente. Per riuscirci è importante proteggere la biodiversità delle coltivazioni e scoprire ingredienti alternativi. La terra ci dona un'infinità di materie prime buone e sostenibili di cui già ampiamente ci serviamo nella nostra cucina, come il grano e i ceci da cui ricavare farine. E a completare un'alimentazione equilibrata ci pensa la nostra farina integrale per dolci o impasti salati, ideale per le sue sostanziose proprietà. La sostenibilità di Altromercato è buona, in tutti i sensi."
	},
	"EC81400": {
		"h1": "Spezie ed erbe aromatiche",
		"desc": "Poche cose parlano di Paesi lontani come le spezie e le erbe aromatiche. I loro colori e profumi ci trasportano subito in atmosfere esotiche come quelle di Asia e Nord Africa, tra mercati vivaci e piatti dal sapore intenso. Il nostro negozio di spezie online raccoglie prodotti equosolidali provenienti da tutto il mondo, inclusa l'Italia. Dai semi di sesamo ai chiodi di garofano, dalla cannella alla curcuma, dai mix di spezie al sale alle erbe aromatiche: ognuno dei prodotti che noi di Altromercato ti proponiamo è stato coltivato nel rispetto dell'ambiente e confezionato con cura da mani esperte.",
		"h2": "Erbe aromatiche e spezie dal mondo",
		"desc2": "La filiera produttiva che noi di Altromercato abbiamo costruito nel tempo coinvolge anche le nostre spezie dal mondo e le profumate erbe aromatiche. Coltiviamo le piante in maniera non intensiva, rispettando la biodiversità del pianeta. Lo facciamo grazie a piccoli produttori il cui lavoro non viene sfruttato ma, al contrario, onorato con un giusto compenso e un trattamento equo. Anche le confezioni delle erbe e spezie del nostro negozio sono sostenibili, perché realizzate in maniera artigianale con carta fatta a mano. Infine, ci teniamo a sottolineare che questi prodotti sono vegani e senza glutine: buoni, equi e solidali per tutti."
	},
	"EC75000": {
		"h1": "Abbigliamento",
		"desc": "On Earth - ethical fashion è il marchio di moda etica di Altromercato, nato per assecondare i desideri di chi ama l'abbigliamento etnico e sostenibile. Tessuti preziosi e materiali di recupero, provenienti da ogni parte del mondo e lavorati in modo artigianale, diventano capi unici di moda da donna ecosostenibile. Abiti, giacche, maglioni, t-shirt, camicie e gonne: ognuno dei modelli che ti proponiamo combina alla perfezione tradizioni lontane e design italiano, etica ed estetica. Per un guardaroba basato su un processo di moda sostenibile che dura nel tempo e che si rivela essere a misura di tutti. ",
		"h2": "La moda etica e solidale On Earth - ethical fashion",
		"desc2": "Che si tratti di abbigliamento etnico realizzato con stoffe provenienti dall'altro capo del mondo, o di moda donna ecosostenibile ottenuta riutilizzando materiali di scarto dell'industria tessile, i capi che noi di Altromercato ti proponiamo non ti lasceranno indifferente. I tessuti vengono valorizzati dal design italiano e dalle sapienti mani degli artigiani, che danno vita ai modelli e conferiscono un valore tutto nuovo alle materie prime. E così anche gli scampoli più umili si trasformano in pezzi unici, che raccontano valori come il rispetto del lavoro e dell'ambiente. Valori che accomunano il nostro operato in Altromercato e le tue scelte quotidiane, anche in fatto di moda."
	},
	"EC85103": {
		"h1": "Portafogli",
		"desc": "La scelta di un portafoglio ecosostenibile è un semplice gesto che aiuta a riscoprire il reale valore delle cose. Nella nostra selezione di portacarte, portamonete e borselli trovi tutto l'impegno che noi di Altromercato mettiamo nella realizzazione etica e sostenibile di accessori per l'abbigliamento. Tutti i prodotti sono realizzati in collaborazione con diverse realtà dell'economia solidale. I nostri partner sono piccole cooperative in Europa, Asia, Africa e America Latina che, con il nostro aiuto, riescono ad affacciarsi sul mercato globale. Insieme selezioniamo i materiali migliori per rispettare i principi dell'economia sostenibile e curiamo ogni dettaglio, dalle rifiniture al packaging, per offrirti portafogli equosolidali belli e pratici.",
		"h2": "Portafogli solidali realizzati con tecniche artigianali",
		"desc2": "Ogni portafoglio etnico della collezione On Earth - ethical fashion nasce dall'esperto lavoro di artigiani ispirati da tecniche tradizionali rispettose dell'ambiente. I materiali utilizzati provengono nella maggior parte dei casi da giacenze di magazzino. Anche la pelle è un materiale di scarto dell'industria alimentare: una scelta che ci permette di realizzare le nostre creazioni limitando l'impatto ambientale e gli sprechi. Scegli uno stile unico e consapevole con un portafoglio ecosostenibile. Una delle tante piccole ma fondamentali decisioni che puoi prendere per aiutare il pianeta e migliorare le condizioni di vita delle persone coinvolte nei processi di produzione."
	},
	"EC74400": {
		"h1": "Confetti",
		"desc": "Rendi le tue occasioni speciali ancora più significative con i confetti equosolidali di Altromercato. I classici dolcetti che accompagnano i momenti più importanti delle nostre vite diventano piccole opportunità per contribuire a un mondo più etico e sostenibile. Nella nostra filiera di produzione collaboriamo solo con piccoli produttori selezionati in diverse zone del mondo, per valorizzare le materie prime e migliorare le condizioni di lavoro delle persone coinvolte nella lavorazione. È possibile fare del bene con la semplice scelta di un confetto? l'impegno quotidiano di tutti noi che lavoriamo in Altromercato è fare in modo che la risposta sia sempre sì.",
		"h2": "I confetti equosolidali Altromercato per i tuoi momenti speciali",
		"desc2": "Nei confetti di Altromercato abbiamo messo tutti i sapori degli ingredienti più pregiati provenienti da Paesi lontani ed esotici. Il cacao e lo zucchero di canna lavorati direttamente in America Latina, o le mandorle coltivate secondo i metodi tradizionali delle comunità agricole palestinesi diventano la base con cui realizziamo queste piccole delizie secondo le ricette storiche italiane. Il nostro sogno è quello di contribuire a dare ancora più valore alle tue occasioni di festa con gusti unici che rappresentino anche nella piccola forma di un confetto l'impegno - tuo e di tutti noi di Altromercato - a rendere il mondo un posto migliore."
	},
	"EC81303": {
		"h1": "Frutta secca e sciroppata, semi",
		"desc": "Dalle noci dell'Amazzonia ai datteri Medjoul al naturale della Palestina: per noi di Altromercato, la frutta secca in vendita online è un viaggio che parte dall'Italia e arriva fino ai sapori d'Oriente, passando dal sud del mondo. Stuzzichini ricchi di vitamine e benefici per il corpo, da sgranocchiare rapidamente tra una sessione di allenamento casalingo e l'altra o durante una videochiamata con i colleghi. La nostra frutta secca e sciroppata equosolidale è prodotta da raccolta spontanea e nel pieno rispetto dell'ecosistema, perché il primo passo verso un futuro migliore parte dalle scelte che compiamo a tavola ogni giorno.",
		"h2": "Frutta secca, sciroppata e semi di Altromercato",
		"desc2": "Quando la voglia di uno snack veloce ti assale non c'è niente di meglio della frutta secca bio che trovi nel nostro negozio online. E se hai bisogno di qualcosa di dolce, perché non provare la frutta sciroppata in barattolo? Dai un'occhiata all'intera selezione e soddisfa la tua voglia di buono: datteri, mandorle, noci, anacardi equosolidali e molto altro ancora. La nostra frutta secca snack mette d'accordo tutti i tipi di palato ed è disponibile in confezioni comode da portare con te o in formato convenienza, da tenere in credenza per ogni occasione."
	},
	"EC81100": {
		"h1": "Zucchero integrale",
		"desc": "È possibile cambiare il mondo scegliendo di acquistare un certo tipo di zucchero piuttosto che un altro? Sembra un piccolo gesto senza importanza, ma attraverso lo zucchero di canna integrale che noi di Altromercato abbiamo selezionato per te puoi contribuire a migliorare la qualità della vita di tante persone. Il sistema di produzione dello zucchero, infatti, è basato troppo spesso su un meccanismo di sfruttamento. A farne le spese sono soprattutto i braccianti impiegati nella raccolta della canna da zucchero, sottopagati e costretti a pesanti turni di lavoro per sostentare la propria famiglia. Una filiera sbagliata, che mette a rischio la salute delle persone e degli ecosistemi coinvolti. Quella che noi di Altromercato sosteniamo, invece, è una filiera completamente diversa.",
		"h2": "Zucchero di canna integrale Altromercato: una scelta responsabile",
		"desc2": "La proposta di zucchero di canna integrale di Altromercato cambia le fondamenta del il mercato grazie a una filiera di produzione etica e sostenibile. Una selezione che arriva nel nostro shop direttamente dai produttori, senza intermediazione. Il modo migliore per sostenere le comunità locali e contribuire alla loro crescita, sia economica che imprenditoriale. Le famiglie di coltivatori diventano nostre partner a tutti gli effetti e ci aiutano a sviluppare e sostenere processi di produzione che restituiscono dignità alle lavoratrici e ai lavoratori, e che limitano al minimo l'impatto ambientale dello zucchero. Una rivoluzione a cui noi di Altromercato prendiamo parte con orgoglio."
	},
	"EC85101": {
		"h1": "Borse e zaini",
		"desc": "Che tu sia tipo da bauletto o pochette poco importa: nella collezione di borse e zaini On Earth - ethical fashion e Upcycling Creation di Altromercato ce n'è per tutti i gusti. Se cerchi una borsetta da spalla per una serata tra amici o un comodo zaino per il tuo computer da usare tutti i giorni, sul nostro negozio online trovi una vasta gamma di modelli e colori: non ti resta che fare la tua scelta consapevole. Sì, perché comprare uno degli accessori che ti proponiamo vuol dire rispettare il pianeta e il lavoro di chi partecipa all'intera filiera di produzione: per tutti quelli che credono nell'armonia tra uomo e ambiente.",
		"h2": "Borse e zaini ecosostenibili di Altromercato",
		"desc2": "Il futuro dipende dalle scelte che compiamo oggi: così recitano le grafiche realizzate a mano su alcuni degli zaini e borse ecosostenibili targati On Earth - Upcycling Creation di Altromercato. Se hai a cuore il rispetto per il pianeta e per i diritti dei lavoratori, i nostri accessori equosolidali e rispettosi dell'ambiente non possono mancare nel tuo armadio. Pelle, cotone e tessuti di prima qualità, lavorati da artigiani italiani e dei Paesi del Sud del mondo, danno vita a borse e zaini etici perfetti per completare ogni tipo di look, dal più sofisticato a quello casual."
	},
	"EC81204": {
		"h1": "Creme al cacao",
		"desc": "Spalmare un velo di morbida crema al cacao su una fetta di pane e sentire il suo sapore intenso che si sprigiona in bocca al primo morso: una sensazione che tutti conosciamo, e amiamo. Una sensazione che dà ancora più gioia se quella che scegliamo di gustare è una crema al cacao equosolidale. Noi di Altromercato abbiamo selezionato i migliori ingredienti, provenienti dalle migliori origini, per dare vita a prodotti davvero buoni per tutti. Per il loro sapore, certo, ma soprattutto perché rispettano l'equilibrio dell'ecosistema in cui viviamo e i diritti delle persone che li producono. ",
		"h2": "La crema al cacao equosolidale di Altromercato",
		"desc2": "Ogni crema spalmabile che trovi nel nostro negozio online è il frutto della filiera etica del cacao che da lungo tempo supportiamo. Il nostro cacao non minaccia la biodiversità dei luoghi in cui viene prodotto, e sostiene i diritti umani dei coltivatori e delle loro famiglie. Per un risultato finale dal gusto irresistibile lo combiniamo con frutta secca come nocciole e anacardi, e aggiungiamo un pizzico di zucchero di canna. La consistenza densa e morbida e il sapore avvolgente e persistente fanno il resto: la nostra crema al cacao equosolidale sa conquistare tutti, grandi e bambini."
	},
	"EC81300": {
		"h1": "Biscotti e cereali colazione",
		"desc": "Porta in tavola la solidarietà sin dalla colazione con i biscotti naturali Altromercato. Un assortimento di cereali e snack dolci per iniziare la giornata con energia e gusto, senza rinunciare a un piccolo gesto per aiutare il pianeta e le persone che lo abitano. Per i biscotti biologici e i cereali, noi di Altromercato abbiamo selezionato ingredienti equosolidali provenienti sia da filiere corte italiane che da coltivazioni in diversi Paesi del mondo. Così il grano e il frumento prodotti in Italia incontrano gli anacardi indiani e la farina di riso thailandese, e i frollini tradizionali si arricchiscono con la quinoa dall'America Latina e con il cacao.",
		"h2": "Biscotti ai cereali per una colazione equa e solidale",
		"desc2": "Quando scegliamo i partner con cui collaborare, noi di Altromercato facciamo attente valutazioni sulle condizioni sociali ed economiche dei lavoratori e delle loro comunità. Il nostro impegno quotidiano consiste soprattutto nell'aiutare piccole realtà in ogni angolo del mondo ad affacciarsi sul mercato globale. Esistono saperi e ingredienti da scoprire e valorizzare, capaci di rendere unico anche un biscotto o i cereali per la colazione. La nostra ricerca di partner ci ha portato a individuare materie prime di qualità per dare valore alla colazione di tante persone. Nuove scoperte di gusto, semplici e golosi gesti che aiutano comunità rurali vicine e lontane."
	},
	"EC72600": {
		"h1": "Aromaterapia",
		"desc": "l'aromaterapia è un'arte antica che sfrutta le proprietà degli oli essenziali per migliorare il benessere delle persone. Le essenze della linea Natyr Altromercato sono tutte pure e realizzate per distillazione a partire da ingredienti completamente naturali. Menta, eucalipto, zenzero e altri elementi aromatici, disponibili separatamente o combinati in sinergie che ne esaltano gli effetti. Ogni olio essenziale che noi di Altromercato ti proponiamo ha un suo utilizzo preciso, che troverai specificato all'interno delle singole descrizioni. Puoi scegliere il tuo preferito o acquistarne più di uno, per circondarti di profumi veri e intensi, sempre diversi giorno dopo giorno.",
		"h2": "Gli oli essenziali per aromaterapia di Altromercato",
		"desc2": "Armonia con il corpo, armonia con il mondo: noi di Altromercato curiamo con attenzione ogni passaggio della filiera produttiva per assicurarci che sia equa e solidale sotto ogni aspetto. Dalla coltivazione di piante, fiori, semi, frutti e radici da cui vengono ricavati gli oli essenziali, fino ai produttori che con il loro sapere estraggono dagli ingredienti i distillati più puri. Acquistando uno degli oli essenziali per aromaterapia Natyr, che sia in purezza o una miscela, sostieni le comunità che collaborano con noi di Altromercato da tutto il mondo. Ogni acquisto è una scelta a cui si lega il tuo benessere e quello di tutte le persone che contribuiscono alla creazione di questi piccoli e profumati elisir."
	},
	"EC82003": {
		"h1": "Dentifrici",
		"desc": "All'interno della linea Natyr noi di Altromercato custodiamo un tesoro fresco e naturale: dentifrici tutti da provare. Abbiamo infatti sviluppato una linea di prodotti per l'igiene personale all'insegna della sostenibilità, che si prende cura di te preservando il nostro pianeta. I nostri dentifrici sono la dimostrazione che la natura ci offre tutto quello di cui abbiamo bisogno: piante ricche di proprietà, da cui estrarre oli essenziali dalle intense punte aromatiche. Per non parlare degli effetti benefici per denti e gengive: due minuti di spazzolino e avrai una bocca fresca al naturale.",
		"h2": "Il cuore del mondo nei prodotti naturali per l'igiene dentale",
		"desc2": "Tutta la bellezza del pianeta arriva sulla mensola del tuo bagno, con i nostri prodotti artigianali per l'igiene orale. Se sceglierai i dentifrici Natyr Altromercato godrai di tutti i benefici legati al tuo benessere quotidiano, e sosterrai l'impegno per un mercato più giusto che ci caratterizza da oltre trent'anni. Sostenibilità, rispetto e responsabilità nei tuoi confronti e verso i produttori a cui ci affidiamo. La nostra idea di mercato non cambia di una virgola anche per quanto riguarda dentifrici e prodotti per l'igiene personale in generale: per una bocca in salute, e un mondo più prospero, equo e solidale."
	},
	"EC85003": {
		"h1": "Top e t-shirt",
		"desc": "Originalità, design e tradizione: te li proponiamo nel nostro negozio online, grazie ai top e alle t-shirt sostenibili ed equosolidali di On Earth - ethical fashion. Capi basici o elaborati per restare al passo con le ultime tendenze della moda, senza mai dimenticare il rispetto per l'ambiente, il pianeta e i diritti dei lavoratori. Le materie prime di qualità, lavorate da mani esperte e piene di talento, si intrecciano con la storia dei produttori locali ai quali abbiamo affidato la realizzazione delle nostre collezioni di top e t-shirt etniche: per un look comodo, unico e totalmente green.",
		"h2": "Top e t-shirt ecosostenibili di Altromercato",
		"desc2": "Tutti i top etnici e le t-shirt di On Earth - ethical fashion e Upcycling Creation Altromercato sono realizzati artigianalmente da produttori locali provenienti dall'Italia e dal Sud del mondo. Dal puro cotone alla seta, passando per il fresco lino d'India: ogni capo d'abbigliamento è morbido, a vestibilità regolare e perfetto per essere indossato in ogni occasione, anche le più speciali. Essere alla moda non vuol dire dimenticare il rispetto per il pianeta e per i diritti dei lavoratori: scegliendo la moda equosolidale che noi di Altromercato abbiamo selezionato per te, avrai la certezza di aver fatto un passo in avanti verso un consumo più consapevole."
	},
	"EC81302": {
		"h1": "Merende, snack e barrette dolci ",
		"desc": "Grazie al commercio equosolidale il momento della merenda diventa anche sostenibile. Con gli snack e le barrette dolci - di cui molte biologiche - di Altromercato ti basta scegliere il gusto che preferisci. Cioccolato, cocco, mirtilli, frutta secca e molti altri ancora: gli ingredienti che abbiamo selezionato per te sono naturali, genuini e prodotti secondo principi di sostenibilità ambientale e prosperità economica. Insieme ai produttori locali, noi di Altromercato ti proponiamo una selezione di merende per ogni momento della giornata. Puoi gustarle fin dal mattino, magari arricchendo il tuo muesli con una manciata di chips di banana. Poi, ogni volta che vuoi, approfitta di una breve pausa per fare il pieno di energia con i benefici naturali della frutta secca.",
		"h2": "Merende dolci e barrette biologiche Altromercato",
		"desc2": "Dalle coltivazioni etiche del pianeta arriva l'ingrediente segreto che rende speciali le nostre merende snack e le barrette, biologiche e non. Parliamo della sostenibilità economica, ambientale e sociale: il valore più prezioso del mercato equo e solidale. Al di là della bontà dei nostri prodotti, è come vengono realizzati che ci sta particolarmente a cuore e ci rende differenti. Rispettiamo la terra senza sfruttarla, garantiamo una possibilità alle piccole economie ed eliminiamo le disuguaglianze con un commercio che crea valore, e poi lo ridistribuisce. Con noi di Altromercato il bello del mondo diventa anche buono."
	},
	"EC81500": {
		"h1": "Vino e birra",
		"desc": "Un bicchiere in buona compagnia è uno dei piccoli grandi piaceri della vita. Un brindisi a base di un vino tipico o di una birra artigianale prodotti in maniera responsabile regala un pizzico di sapore ancora più speciale. Noi di Altromercato ti offriamo una selezione unica di vini e luppoli per accompagnare i tuoi momenti di relax o per impreziosire i tuoi eventi da ricordare. Bollicine, bianchi e rossi coraggiosi, oltre a birre agricole con storie da raccontare e che nascono da un'attenta valutazione delle materie prime, con cicli produttivi rispettosi della natura e del territorio.",
		"h2": "Vini tipici e birre artigianali per la tua tavola",
		"desc2": "È possibile scegliere di bere in modo equo e solidale oltre che responsabile? Per noi di Altromercato la risposta è sì. Riempi i boccali con birre agricole che valorizzano il lavoro dei piccoli produttori locali con ingredienti selezionati e studiati per riscoprire la tradizione. Brinda con grandi vini tipici come il prosecco, il Franciacorta e il moscato dei Colli Euganei. Porta in tavola vini siciliani nati dalla lotta contro la mafia e il pizzo. Un semplice bicchiere o una buona bottiglia da bere in compagnia diventano un gesto di impegno per un mondo migliore."
	},
	"EC81304": {
		"h1": "Chips salati",
		"desc": "Con gli snack da aperitivo Altromercato, un semplice momento di relax casalingo si arricchisce di sapori unici e di sostenibilità. Nei nostri spuntini salati raccogliamo ingredienti e suggestioni dagli angoli più diversi del pianeta per portare sulla tua tavola gusto e biodiversità. Tanti diversi tipi di riso dalla Thailandia, quinoa dal Perù e dalla Bolivia, prodotti secondo filiere etiche ed ecosostenibili. Con gli snack salati che noi di Altromercato abbiamo preparato per te scopri un nuovo modo di vivere l'happy hour, più equosolidale e autentico.",
		"h2": "Gli snack salati sostenibili e leggeri",
		"desc2": "Come tutti i partner con cui noi di Altromercato abbiamo scelto di collaborare, i coltivatori delle materie prime per i nostri spuntini salati si impegnano ogni giorno nei loro campi per tutelare la biodiversità e contrastare la crisi climatica. Insieme, uniamo l'innovazione della scienza con la tradizione agricola locale per ridurre gli sprechi e valorizzare il lavoro delle comunità indigene. Il risultato sono degli snack salati che rendono unica anche una semplice birra bevuta in casa sul divano, con la leggerezza e il benessere che solo ingredienti di qualità come cereali e legumi sanno portare all'organismo. "
	},
	"EC81002": {
		"h1": "Infusi e tisane",
		"desc": "Per tenerti compagnia nei momenti di relax e benessere, noi di Altromercato ti proponiamo una variegata selezione di infusi e tisane equosolidali. Dalla camomilla coltivata sugli altopiani della Bolivia fino ai decotti dagli aromi avvolgenti, preserviamo gli effetti benefici degli ingredienti naturali per portarti tutto il buono che la terra ci ha donato. In ogni bustina dei nostri infusi e tisane in vendita online raccontiamo una piccola storia di cambiamento e rispetto del pianeta. I nostri infusi al cacao, per esempio, sono la dimostrazione di come l'economia circolare sia indispensabile per ridurre gli sprechi: noi recuperiamo le bucce delle fave di cacao tostate e tu sorseggi una bevanda buona e sostenibile.",
		"h2": "Vendita di tisane online e infusi equosolidali Altromercato",
		"desc2": "Incentivare il commercio equo e solidale vuol dire favorire il cambiamento e un'economia sostenibile. Per noi di Altromercato non si tratta semplicemente di vendita di tisane o infusi: vogliamo offrirti la possibilità di fare acquisti consapevoli e di forte impatto, capaci di cambiare il presente e il futuro del pianeta e dei lavoratori. Ti basta attendere il giusto tempo d’infusione per assaporare il gusto dolce e intenso della rivoluzione Altromercato e supportare i produttori etici che rispettano la biodiversità delle coltivazioni."
	}
};